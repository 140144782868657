import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Stack, TextField, Typography } from "@mui/material";

import UserPage from "../../components/organisms/UserPage";
import { useInsightsQuery } from "../../redux/campaignsApi";
import PieChartComponent from "./PieChartComponent";
import { DatePicker } from "@mui/x-date-pickers";
import {
  eachDayOfInterval,
  endOfDay,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { LineChart } from "@mui/x-charts";
import RoundIconButton from "../../components/atoms/RoundIconButton";
import { ArrowBack } from "@mui/icons-material";

const setInterval = (start: Date, end: Date) =>
  eachDayOfInterval({ start, end });

const Statistics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const [dateRange, setRangeDate] = useState<any>({
    from: startOfMonth(new Date()),
    to: endOfDay(new Date()),
  });

  const insights = useInsightsQuery(
    { campaign: id, from: dateRange.from, to: dateRange.to },
    { skip: !id }
  );

  return (
    <UserPage
      px={1}
      flex={1}
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb" sx={{ position: "relative" }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{
              position: "absolute",
              top: (theme) => theme.spacing(2),
              left: (theme) => theme.spacing(1),
            }}
          >
            <RoundIconButton onClick={() => navigate(-1)} color="secondary" sx={{ zIndex: 999999 }}>
              <ArrowBack fontSize="medium" />
            </RoundIconButton>
            <Typography variant="h4">
              Rapport - {location.state.name}
            </Typography>
          </Stack>
        </Breadcrumbs>
      }
    >
      <Stack
        mt={5}
        spacing={1}
        direction="column"
        width="100%"
        textAlign="center"
      >
        <Stack direction="row-reverse" spacing={1}>
          <DatePicker
            mask="__/__/____"
            label="Jusqu'à"
            value={dateRange.to}
            minDate={dateRange.from}
            maxDate={new Date()}
            onChange={(val) =>
              setRangeDate({
                ...dateRange,
                to: endOfDay(val),
              })
            }
            renderInput={(params: any) => <TextField {...params} />}
          />
          <DatePicker
            mask="__/__/____"
            label="Du"
            value={dateRange.from}
            maxDate={dateRange.to}
            onChange={(val) =>
              setRangeDate({
                ...dateRange,
                from: startOfDay(val),
              })
            }
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Stack>
        <LineChart
          height={400}
          series={[
            {
              data:
                insights.data?.viewsAndclicksByPeriod?.map(
                  (item: any) => item.views
                ) || [],
              label: `${insights.data?.viewsAndclicks?.views || 0} Impressions`,
            },
            {
              data:
                insights.data?.viewsAndclicksByPeriod?.map(
                  (item: any) => item.clicks
                ) || [],
              label: `${insights.data?.viewsAndclicks?.clicks || 0} Clics`,
            },
          ]}
          xAxis={[
            {
              scaleType: "time",
              data: insights.data?.viewsAndclicksByPeriod?.length
                ? insights.data?.viewsAndclicksByPeriod?.map(
                    (item: any) => new Date(item._id)
                  )
                : setInterval(dateRange.from, dateRange.to),
              tickMinStep: 3600 * 1000 * 24,
              valueFormatter: (date: Date) =>
                date.toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }),
              tickLabelStyle: {
                angle: 45,
                textAnchor: "start",
                fontSize: 9,
              },
            },
          ]}
        />
      </Stack>
      <Stack
        mt={5}
        spacing={1}
        direction="row"
        justifyContent="center"
        width="100%"
        textAlign="center"
      >
        {insights.data?.viewsAndclicksByCategory?.length > 0 && (
          <PieChartComponent
            data={
              insights.data?.viewsAndclicksByCategory?.map((item: any) => ({
                id: item._id,
                value: item.views,
                label: item.category.name,
              })) || []
            }
            title="Impressions par catégorie"
          />
        )}
        {insights.data?.viewsAndclicksByCategory?.length > 0 && (
          <PieChartComponent
            data={
              insights.data?.viewsAndclicksByCategory?.map((item: any) => ({
                id: item._id,
                value: item.clicks,
                label: item.category.name,
              })) || []
            }
            title="Clics par catégorie"
          />
        )}
      </Stack>
      <Stack
        mt={5}
        mb={10}
        spacing={1}
        direction="row"
        justifyContent="center"
        width="100%"
        textAlign="center"
      >
        {insights.data?.viewsAndclicksByPlacement?.length > 0 && (
          <PieChartComponent
            data={
              insights.data?.viewsAndclicksByPlacement?.map((item: any) => ({
                value: item.views,
                label: item.placement,
              })) || []
            }
            title="Impressions par page"
          />
        )}
        {insights.data?.viewsAndclicksByPlacement?.length > 0 && (
          <PieChartComponent
            data={
              insights.data?.viewsAndclicksByPlacement?.map((item: any) => ({
                value: item.clicks,
                label: item.placement,
              })) || []
            }
            title="Clics par page"
          />
        )}
      </Stack>
    </UserPage>
  );
};

export default Statistics;
