import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "../../lib/firebase";
import authApi from "../authApi";
import { autocompleteApi, geocodingApi } from "../geocodingApi";

export type SigninPayload = {
  email: string;
  password: string;
};

export const signin = createAsyncThunk(
  "auth/signin",
  async ({ email, password }: SigninPayload) => {
    await signInWithEmailAndPassword(auth, email, password);
  }
);

export const logout = createAsyncThunk("logout", async (_, { dispatch }) => {
  auth.signOut();
  dispatch(authApi.util.resetApiState());
  dispatch(geocodingApi.util.resetApiState());
  dispatch(autocompleteApi.util.resetApiState());
});
