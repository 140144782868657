import React, { ComponentProps } from "react";
import { Field, FieldProps, useField } from "formik";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import PhoneInput, {
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import countries from "i18n-iso-countries";

import EmptyList from "../../molecules/EmptyList";

const ComponentContext = React.createContext({
  name: "",
});

countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

const otherCountries: {
  [key: string]: {
    fr: string;
  };
} = {
  AC: {
    fr: "Île de l'Ascension",
  },
  TA: {
    fr: "Sainte-Hélène",
  },
};

const CustomInput = React.forwardRef(
  ({ onKeyDown, ...props }: any, ref: any) => {
    const { name } = React.useContext(ComponentContext);
    const [, meta] = useField(name);
    const theme = useTheme();

    return (
      <Box
        sx={{
          flex: 1,
          "& > input": {
            border: `1px solid ${
              meta.touched && !!meta.error ? theme.palette.error.main : "#AAA"
            }`,
            borderRadius: 1,
            padding: "18px 14.5px",
            fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(
              ","
            ),
            fontSize: "1rem",
            width: "100%",
          },
        }}
      >
        <input
          ref={ref}
          {...props}
          value={
            props.value?.startsWith("+")
              ? parsePhoneNumber(props.value)?.nationalNumber || ""
              : props.value
          }
        />
      </Box>
    );
  }
);

const Container = (props: any) => (
  <Stack direction="row" spacing={1} {...props} />
);

const getCountryCodeLabel = (val: string, countryVisible: boolean) => {
  if (val) {
    const callingCode = getCountryCallingCode(val as any);
    const countryName =
      countries.getName(val, "fr") || otherCountries[val]?.fr || val;
    return `(+${callingCode})${countryVisible ? " " + countryName : ""}`;
  }

  return "";
};

const SelectComponent = React.forwardRef(
  ({ iconComponent, ...props }: any, ref) => {
    const { name } = React.useContext(ComponentContext);
    const [, meta] = useField(name);
    const [search, setSearch] = React.useState("");
    const filteredOptions = React.useMemo(() => {
      return props.options.filter((option: any) => {
        if (!search) {
          return true;
        }

        return (
          option.value?.toLowerCase().includes(search.toLowerCase()) ||
          option.label?.toLowerCase().includes(search.toLowerCase()) ||
          (option.value &&
            getCountryCallingCode(option.value as any)?.includes(
              search.toLowerCase()
            ))
        );
      });
    }, [props.options, search]);

    const IconComponent = iconComponent;

    return (
      <FormControl>
        <InputLabel>Pays</InputLabel>
        <Select
          ref={ref}
          {...props}
          label="Pays"
          renderValue={(value) => {
            if (!value) {
              return null;
            }

            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconComponent country={value as any} label={value as any} />
                <Typography>
                  {getCountryCodeLabel(value as any, false)}
                </Typography>
              </Stack>
            );
          }}
          value={props.value}
          error={meta.touched && !!meta.error}
          MenuProps={{
            sx: {
              paddingTop: 70,
            },
            PaperProps: {
              sx: {
                width: "100%",
                height: "100%",
                top: "0!important",
              },
            },
          }}
          onChange={(evt) => props.onChange(evt.target.value)}
        >
          <Box
            p={1}
            sx={{
              position: "sticky",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <TextField
              InputProps={{
                startAdornment: <Search />,
                inputProps: {
                  autocomplete: "nothing",
                },
              }}
              label="Rechercher"
              onChange={(evt) => setSearch(evt.target.value)}
              fullWidth
            />
          </Box>
          {filteredOptions.length === 0 && (
            <EmptyList message="Aucun pays correspond à votre recherche" />
          )}
          {filteredOptions
            .filter((option: any) => !!option.value)
            .map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {option.value && option.label && (
                    <IconComponent
                      country={option.value}
                      label={option.label}
                    />
                  )}
                  <Typography>
                    {getCountryCodeLabel(option.value, true)}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
);

const FormikPhoneNumberInput = ({
  name,
  extName,
  countryName,
}:
  | { name: string; extName?: never; countryName?: never }
  | ({
      name: string;
      extName: string;
      countryName: string;
    } & Omit<ComponentProps<typeof PhoneInput>, "onChange" | "value">)) => {
  return (
    <ComponentContext.Provider
      value={{
        name,
      }}
    >
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Stack>
            <PhoneInput
              {...field}
              value={field.value}
              defaultCountry={
                field.value
                  ? (parsePhoneNumber(field.value)?.country as any)
                  : "FR"
              }
              onChange={(value) => {
                form.setFieldValue(field.name, value);

                if (extName && countryName && value) {
                  form.setFieldValue(
                    extName,
                    parsePhoneNumber(value, form.values[countryName])
                      ?.nationalNumber || ""
                  );
                }
              }}
              inputComponent={CustomInput}
              containerComponent={Container}
              countrySelectComponent={SelectComponent}
              onCountryChange={(country) => {
                if (countryName) {
                  form.setFieldValue(countryName, country);
                }
              }}
            />

            {form.errors[name] && (
              <FormHelperText error={!!form.errors[name]}>
                {form.errors[name] as string}
              </FormHelperText>
            )}
          </Stack>
        )}
      </Field>
    </ComponentContext.Provider>
  );
};

export default FormikPhoneNumberInput;
