import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";

const campaignAdSetsApi = createApi({
  reducerPath: "adSetsApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["AdSet"],
  endpoints(build) {
    return {
      getAdSets: build.query({
        query: ({
          $limit,
          $skip,
          sortField = "createdAt",
          sortOrder = -1,
          search = "",
        }) => {
          let url = `/adsets?$limit=${$limit}&$skip=${$skip}&$sort[${sortField}]=${sortOrder}`;
          if (search) {
            url = `${url}&search=${search}`;
          }
          return {
            url,
            method: "get",
          };
        },
        providesTags: ["AdSet"],
      }),
      createAdSet: build.mutation({
        query: (data) => ({
          url: `/adsets`,
          method: "post",
          data,
        }),
        invalidatesTags: ["AdSet"],
      }),
      updateAdSet: build.mutation({
        query: ({ id, data }) => ({
          url: `/adsets/${id}`,
          method: "patch",
          data,
        }),
        invalidatesTags: ["AdSet"],
      }),
      deleteAdSet: build.mutation({
        query: ({ id }) => ({
          url: `/adsets/${id}`,
          method: "delete",
        }),
        invalidatesTags: ["AdSet"],
      }),
    };
  },
});

export const {
  useGetAdSetsQuery,
  useCreateAdSetMutation,
  useUpdateAdSetMutation,
  useDeleteAdSetMutation,
} = campaignAdSetsApi;

export default campaignAdSetsApi;
