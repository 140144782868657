import React from "react";
import { useAppDispatch } from "../../app/hooks";
import { logout } from "../../redux/auth/thunks";

const Logout = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return null;
};

export default Logout;
