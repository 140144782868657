import React from 'react';
import { GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api';
import {Helmet} from 'react-helmet';
import {Breadcrumbs, LinearProgress, Paper, Typography} from '@mui/material';

import UserPage from '../../components/organisms/UserPage';
import { useGetBusinessesQuery } from '../../redux/businessesApi';
import { InfoWindowContent } from '../../components/atoms/formik/GeoLocationPicker/GeoAddressPicker';
import PIN_BUSINESS from "../../assets/images/pin.png";
import PIN_USER from "../../assets/images/pin-2.png";
import useGeolocation from '../../lib/hooks/use-geolocation';
import { useGetAuthUser } from '../../lib/hooks/useGetAuthUserId';

const initialRegion = {
  latitude: 48.8566,
  longitude: 2.3522,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const QRCodes = () => {
  const [, setMap] = React.useState<google.maps.Map>();
  const [selectedBusiness, setSelectedBusiness] = React.useState<any>();
  const [businessesCoords, setBusinessesCoords] = React.useState<
      Map<string, google.maps.Marker>
    >(new Map());
  const [userLocation, setUserLocation] = React.useState<LatLng>(initialRegion);

  const authUser = useGetAuthUser();
  const { position } = useGeolocation();
  const authUserCoords = authUser?.businessProfile?.address?.location;
  const businessesQuery = useGetBusinessesQuery(
    {userLocation},
    { skip: !authUserCoords?.length || !position?.coords }
  );

  React.useEffect(() => {
    const latLng =
      authUserCoords?.length
        ? { latitude: authUserCoords[1], longitude: authUserCoords[0] }
        : { latitude: position?.coords?.latitude, longitude: position?.coords?.longitude }
    setUserLocation(latLng);
  }, [authUserCoords, position]);

  return (
    <UserPage
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="h6" color="text.primary">
            Nos QR codes
          </Typography>
        </Breadcrumbs>
      }>
      <Helmet><title>Nos QR codes</title></Helmet>
      {businessesQuery.isLoading && <LinearProgress color="inherit" />}
      <Paper sx={{ width: "100%", height: "74vh" }} variant='outlined'>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lng: userLocation.longitude,
            lat: userLocation.latitude,
          }}
          zoom={10}
          onLoad={(map) => setMap(map)}
          onUnmount={() => setMap(undefined)}
        >
          <MarkerF
            position={{
              lng: userLocation.longitude,
              lat: userLocation.latitude,
            }}
            icon={PIN_USER}
          />

          {businessesQuery.data?.data?.length && (
            <>
              {businessesQuery.data?.data?.map((business: any) => (
                <MarkerF
                  key={business._id}
                  position={{
                    lat: business.location.coordinates[1],
                    lng: business.location.coordinates[0],
                  }}
                  icon={{
                    url: PIN_BUSINESS,
                    labelOrigin: new google.maps.Point(16, 13),
                    scaledSize: new google.maps.Size(10, 10),
                  }}
                  onClick={() =>
                    setSelectedBusiness({
                      id: business._id,
                      name: business.name,
                      qrcode: business.qrCodeQuantity || 1,
                      position: {
                        lat: business.location.coordinates[1],
                        lng: business.location.coordinates[0],
                      },
                      category: business.category.name,
                      address: business.address,
                      logo: business.image.reference,
                    })
                  }
                  onLoad={(mapMarker) => {
                    setBusinessesCoords(
                      new Map(
                        businessesCoords.set(business._id, mapMarker)
                      )
                    );
                  }}
                  title={`Nombre de QR code(s) : ${
                    business.qrCodeQuantity || 1
                  }`}
                />
              ))}
              {selectedBusiness && (
                <InfoWindowF
                  position={selectedBusiness.position}
                  onCloseClick={() => setSelectedBusiness(null)}
                >
                  <InfoWindowContent business={selectedBusiness} />
                </InfoWindowF>
              )}
            </>
          )}
        </GoogleMap>
      </Paper>
    </UserPage>
  );
};

export default QRCodes;
