import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";

const useGeolocation = () => {
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      NotificationManager.error("Geolocation is not supported");
      return;
    }
    const watcher = geo.watchPosition(setPosition, setError);
    return () => geo.clearWatch(watcher);
  }, []);

  return { position, error };
};

export default useGeolocation;
