import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "./firebase";

const AuthRoute = () => {
  const [authenticated, setAuthenticated] = React.useState<boolean>();
  const navigate = useNavigate();

  React.useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/login");
      } else {
        setAuthenticated(true);
      }
    });

    return () => unsub();
  }, [navigate]);

  if (authenticated) {
    return <Outlet />;
  }

  return null;
};

export default AuthRoute;
