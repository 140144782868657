import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { GoogleMap, MarkerF, InfoWindowF, CircleF } from "@react-google-maps/api";
import { useDebouncedCallback } from "use-debounce";

import {
  useGeoJSONQuery,
  useGetPlaceByIdQuery,
  useLazyAutoCompletePlacesQuery,
} from "../../../../redux/geocodingApi";
import useQueryInternalError from "../../../../lib/hooks/useQueryInternalError";
import { useGetBusinessesQuery } from "../../../../redux/businessesApi";
// import useGeolocation from "../../../../lib/hooks/use-geolocation";
import PIN_BUSINESS from "../../../../assets/images/pin.png";
import PIN_USER from "../../../../assets/images/pin-2.png";
import FirebaseStorageImage from "../../FirebaseStorageImage";

const initialRegion = {
  latitude: 48.8566,
  longitude: 2.3522,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const GeoAddressPicker = React.forwardRef(
  (
    {
      location,
      address = "",
      onSave,
      onClose,
      fullAddress = true,
    }: {
      location: LatLng;
      address: string;
      onSave: (location: LatLng, address: string, geoJSON?: any) => void;
      onClose: () => void;
      fullAddress?: boolean;
    },
    ref: any
  ) => {
    const [touched, setTouched] = React.useState(false);
    const [error, setError] = React.useState<any>({});
    const [coords, setCoords] = React.useState<LatLng>(
      location ? location : initialRegion
    );
    const [search, setSearch] = React.useState(address);
    const [selectedAddress, setSelectedAddress] = React.useState("");
    const [region, setRegion] = React.useState<LatLng>(
      location ? location : initialRegion
    );
    const [businessesCoords, setBusinessesCoords] = useState<
      Map<string, google.maps.Marker>
    >(new Map());
    const [selectedBusiness, setSelectedBusiness] = useState<any>();
    const [placeId, setPlaceId] = React.useState<string>();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [forceMenuClose, setForceMenuClose] = React.useState(true);

    const [map, setMap] = React.useState<google.maps.Map>();
    const [mapLayer,] = React.useState<google.maps.Data>(new google.maps.Data());

    const [triggerACPlaces, placesQuery] = useLazyAutoCompletePlacesQuery();

    const debouncedTriggerACPlaces = useDebouncedCallback(triggerACPlaces, 250);

    const placeQuery = useGetPlaceByIdQuery(placeId as string, {
      skip: !placeId,
    });

    const geoJSONQuery = useGeoJSONQuery(selectedAddress, { skip: !selectedAddress });
    const businessesQuery = useGetBusinessesQuery(
      { userLocation: region },
      { skip: !region }
    );

    // const { position } = useGeolocation();
    /* const mapFitBounds = React.useCallback(() => {
      if (!map) return;

      const bounds = new google.maps.LatLngBounds();
      businessesCoords.map((loc: any) => bounds.extend(new google.maps.LatLng(loc.lat, loc.lng)));

      map.fitBounds(bounds);
    }, [map, businessesCoords]); */

    useQueryInternalError(placeQuery);
    useQueryInternalError(placesQuery);

    React.useEffect(() => {
      if (search?.length >= 3) {
        debouncedTriggerACPlaces(search);
      }
    }, [debouncedTriggerACPlaces, search]);

    React.useEffect(() => {
      if (
        !forceMenuClose &&
        (placesQuery.isSuccess || placesQuery.isFetching)
      ) {
        setMenuOpen(true);
      }
    }, [forceMenuClose, placesQuery.isFetching, placesQuery.isSuccess]);

    React.useEffect(() => {
      if (placeQuery.isSuccess && placeQuery.data.result.geometry) {
        setRegion({
          longitude: placeQuery.data?.result.geometry.location.lng,
          latitude: placeQuery.data?.result.geometry.location.lat,
        });

        setCoords({
          longitude: placeQuery.data?.result.geometry.location.lng,
          latitude: placeQuery.data?.result.geometry.location.lat,
        });

        if (fullAddress) {
          const hasStreetName = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes("route")
          );

          const hasCity = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes("locality")
          );

          const hasCountry = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes("country")
          );

          const hasPostalCode = placeQuery.data.result.address_components?.some(
            (component: any) => component.types.includes("postal_code")
          );

          setError({
            streetName: !hasStreetName,
            city: !hasCity,
            country: !hasCountry,
            postalCode: !hasPostalCode,
          });
        }
      }

      if (map && geoJSONQuery.data?.length) {
        mapLayer.forEach((feature) => mapLayer.remove(feature));
        if (geoJSONQuery.data[0]?.addresstype === "country") {
          mapLayer.setStyle({
            fillColor: "#8eaadb",
            strokeColor: "#698ecf",
            fillOpacity: 0.4
          });
          mapLayer.addGeoJson({
            "id": geoJSONQuery.data[0]?.place_id,
            "type": "FeatureCollection",
            "features": [{
              "type": "Feature",
              "geometry": {
                "type": geoJSONQuery.data[0]?.geojson.type,
                "coordinates": geoJSONQuery.data[0]?.geojson.coordinates,
              },
            }]
          });
        }
        mapLayer.setMap(map);
      }
    }, [map, fullAddress, geoJSONQuery.data, placeQuery, mapLayer]);

    const errorMessage = React.useMemo(() => {
      if (error.streetName) {
        return "Nom de rue manquant";
      }

      if (error.city) {
        return "Ville manquante";
      }

      if (error.country) {
        return "Pays manquant";
      }

      if (error.postalCode) {
        return "Code postal manquant";
      }

      if (!placeId) {
        return "Adresse manquante";
      }

      return null;
    }, [
      error.city,
      error.country,
      error.postalCode,
      error.streetName,
      placeId,
    ]);

    return (
      <Paper ref={ref} sx={{ width: "100%", height: "100%", tabIndex: -1 }}>
        <Stack
          sx={{
            width: "100%",
            height: "100%",
          }}
          direction="column"
          spacing={2}
          p={2}
        >
          <TextField
            variant="outlined"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onFocus={() => setForceMenuClose(false)}
            onBlur={() => setTouched(true)}
            label="Choisissez une addresse"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setSearch("");
                    setPlaceId(undefined);
                  }}
                >
                  <Close />
                </IconButton>
              ),
            }}
            helperText={
              (!!errorMessage || !placeId) && touched ? errorMessage : undefined
            }
            error={touched && (!!errorMessage || !placeId)}
          />

          <Typography variant="body1">
            Nombre de QR codes: { businessesQuery.data?.data?.length &&
              (businessesQuery.data?.data?.reduce((accumulator: number, business: any) => accumulator + (business.qrCodeQuantity || 1), 0) || 0)
            }
          </Typography>

          <Stack
            sx={{
              flex: 1,
            }}
            direction="column"
            spacing={2}
          >
            <Box
              sx={{
                position: "relative",
                flex: 1,
              }}
            >
              {placeQuery.isLoading && <LinearProgress color="inherit" />}
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                  lat: region.latitude, // position?.coords?.latitude ||
                  lng: region.longitude, // position?.coords?.longitude ||
                }}
                zoom={8}
                onLoad={(map) => setMap(map)}
                onUnmount={() => setMap(undefined)}
              >
                {coords && (
                  <>
                    <MarkerF
                      position={{
                        lng: coords.longitude,
                        lat: coords.latitude,
                      }}
                      icon={PIN_USER}

                    />
                    { (geoJSONQuery.data?.length && geoJSONQuery.data[0]?.addresstype !== "country") &&
                      <CircleF
                        center={{
                          lng: coords.longitude,
                          lat: coords.latitude,
                        }}
                        radius={50000}
                        options={{
                          fillColor: "#8eaadb",
                          strokeColor: "#698ecf",
                          fillOpacity: 0.4
                        }}
                      />
                    }
                  </>
                )}
                {businessesQuery.data?.data?.length && (
                  <>
                    {businessesQuery.data?.data?.map((business: any) => (
                      <MarkerF
                        key={business._id}
                        position={{
                          lat: business.location.coordinates[1],
                          lng: business.location.coordinates[0],
                        }}
                        icon={{
                          url: PIN_BUSINESS,
                          labelOrigin: new google.maps.Point(16, 13),
                          scaledSize: new google.maps.Size(10, 10),
                        }}
                        onClick={() =>
                          setSelectedBusiness({
                            id: business._id,
                            name: business.name,
                            qrcode: business.qrCodeQuantity || 1,
                            position: {
                              lat: business.location.coordinates[1],
                              lng: business.location.coordinates[0],
                            },
                            category: business.category.name,
                            address: business.address,
                            logo: business.image.reference,
                          })
                        }
                        onLoad={(mapMarker) => {
                          setBusinessesCoords(
                            new Map(
                              businessesCoords.set(business._id, mapMarker)
                            )
                          );
                        }}
                        title={`Nombre de QR code(s) : ${
                          business.qrCodeQuantity || 1
                        }`}
                      />
                    ))}
                    {selectedBusiness && (
                      <InfoWindowF
                        position={selectedBusiness.position}
                        onCloseClick={() => setSelectedBusiness(null)}
                      >
                        <InfoWindowContent business={selectedBusiness} />
                      </InfoWindowF>
                    )}
                  </>
                )}
              </GoogleMap>

              {menuOpen && !forceMenuClose && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "-50px",
                    width: "100%",
                    height: "85vh",
                    display: "flex",
                    backgroundColor: (theme) => theme.palette.common.white,
                  }}
                >
                  {placesQuery.isLoading && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        position: "absolute",
                        inset: 0,
                        backgroundColor: (theme) => theme.palette.common.white,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  {placesQuery.isSuccess &&
                    placesQuery.data.predictions?.length > 0 && (
                      <List sx={{ backgroundColor: "#fff" }}>
                        {placesQuery.data.predictions.map((feature) => (
                          <ListItem
                            sx={{
                              pl: 1,
                            }}
                            key={feature.place_id}
                            onClick={(event) => {
                              if (feature.place_id) {
                                setPlaceId(feature.place_id);
                                setSelectedAddress(feature.description);
                                setSearch(feature.description);
                                setForceMenuClose(true);
                                setMenuOpen(false);
                              }
                              event.stopPropagation();
                            }}
                          >
                            <ListItemText>{feature.description}</ListItemText>
                          </ListItem>
                        ))}
                        <Divider sx={{ m: 2 }} />
                        <Box p={2}>
                          <Typography variant="body2" align="center">
                            Fin
                          </Typography>
                        </Box>
                      </List>
                    )}
                  {placesQuery.isSuccess &&
                    placesQuery.data.predictions?.length === 0 && (
                      <Box
                        display="flex"
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="body1">
                          Aucune adresse n'a été trouvée
                        </Typography>
                      </Box>
                    )}
                </Box>
              )}
            </Box>
          </Stack>
          <Stack px={2} direction="row" justifyContent="center" spacing={1}>
            <Button
              sx={{ flex: 1 }}
              variant="contained"
              onClick={() => {
                if (placeId && !errorMessage) {
                  onSave(
                    coords,
                    selectedAddress,
                    geoJSONQuery.data?.length ? geoJSONQuery.data[0].geojson : null
                  );
                } else {
                  setTouched(true);
                }
              }}
              disabled={placesQuery.isLoading || placeQuery.isLoading}
            >
              Valider
            </Button>
            <Button
              sx={{ flex: 1 }}
              variant="outlined"
              onClick={() => {
                onClose();
              }}
            >
              Annuler
            </Button>
          </Stack>
        </Stack>
      </Paper>
    );
  }
);

export const InfoWindowContent = ({ business }: any) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <FirebaseStorageImage
              reference={business.logo as string}
              width={200}
              ratio="1:1"
              style={{ textAlign: "center" }}
            />
          </Box>
          <CardContent>
            <Typography component="div" variant="h5">
              {business.name}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {business.address}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              {business.category}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              Nombre de QR code(s) : {business.qrcode}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default GeoAddressPicker;
