import { useState } from "react";

import PageList from "../../components/organisms/PageList";
import EmptyList from "../../components/molecules/EmptyList";
import {
  useGetCampaignByIdQuery,
  useGetCampaignsQuery,
} from "../../redux/campaignsApi";
import CampaignCard from "./CampaignsCard";
import useGetAuthUserId from "../../lib/hooks/useGetAuthUserId";
import Preview from "../preview";

const CampaignsList = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const owner = useGetAuthUserId();
  const query = useGetCampaignByIdQuery(
    { id: selectedCampaign },
    { skip: !selectedCampaign }
  );

  return (
    <>
      <PageList
        addButtonVisible
        addLink="/actions/campaigns/create"
        pageTitle="Campagnes"
        useQuery={useGetCampaignsQuery}
        emptyList={
          <EmptyList message="Aucune campagne ne correspond à votre recherche" />
        }
        extraQueryParams={{ owner: owner?.id }}
        renderItem={(campaign: any) => (
          <CampaignCard
            key={campaign._id}
            campaign={campaign}
            setSelectedCampaign={setSelectedCampaign}
          />
        )}
        useQuerySkip={!owner?.id}
      />
      {query.isSuccess && (
        <Preview
          ad={{
            headline: query.data?.ad?.headline,
            primaryText: query.data?.ad?.primaryText,
            description: query.data?.ad?.description,
            mediaRef: query.data?.media?.reference,
            imageAspectRatio: query.data?.ad?.imageAspectRatio,
          }}
          hidePreviewButton={true}
          open={!!selectedCampaign}
          callback={() => setSelectedCampaign(null)}
        />
      )}
    </>
  );
};

export default CampaignsList;
