import { createSlice } from "@reduxjs/toolkit";
import store from "../../app/store";
import { auth } from "../../lib/firebase";
import { clearRTKApiCache } from "./clearRTKApiCache";

const initialState: {
  authenticated?: boolean;
} = {
  authenticated: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedOut(state) {
      state.authenticated = false;
    },
    loggedIn(state) {
      state.authenticated = true;
    },
  },
});

export const { loggedOut, loggedIn } = authSlice.actions;

auth.onAuthStateChanged((user) => {
  if (!user) {
    store.dispatch(loggedOut());
    clearRTKApiCache();
  } else {
    store.dispatch(loggedIn());
  }
});

export const authReducer = authSlice.reducer;

export default authSlice;
