import React from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import {
  Check,
  Block,
  MoreVert,
  // AdsClick,
  // Visibility,
  DateRange,
  Money,
} from "@mui/icons-material";

import { internalError } from "../../lib/internalError";
import {
  usePatchCampaignMutation,
  useDeleteCampaignMutation,
} from "../../redux/campaignsApi";
// import config from "../../config.json";

const CampaignCard: React.FC<{
  campaign: any;
  setSelectedCampaign?: any;
}> = ({ campaign, setSelectedCampaign }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteCampaign, mutation] = useDeleteCampaignMutation();
  const [patchCampaign, patchMutation] = usePatchCampaignMutation();

  React.useEffect(() => {
    if (mutation.isError) {
      internalError(mutation.error);
    }
    if (mutation.isSuccess) {
      NotificationManager.success("La Campagne a été supprimée avec succès");
      setAnchorEl(null);
    }

    if (patchMutation.isSuccess) {
      setAnchorEl(null);
    }
  }, [
    mutation.error,
    mutation.isError,
    mutation.isSuccess,
    patchMutation.isSuccess,
  ]);

  const onRemoveCampaign = React.useCallback(
    async (id: string) => {
      if (
        window.confirm("Êtes-vous sûr vouloir supprimer cette campagne?") ===
        true
      ) {
        await deleteCampaign({ id });
      }
    },
    [deleteCampaign]
  );

  const onPatchCampaign = React.useCallback(
    async (id: string, status: number) => {
      try {
        patchCampaign({ id, data: { status: status === 1 ? 0 : 1 } });
      } catch (error) {
        console.log("patch error: ", error);
      }
    },
    [patchCampaign]
  );

  const openActions = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  return (
    <Paper
      square
      sx={{ py: 2, px: 2, position: "relative", paddingBottom: 2 }}
      variant="outlined"
    >
      {(mutation.isLoading || patchMutation.isLoading) && <LinearProgress />}
      <IconButton
        sx={{ position: "absolute", top: 10, right: 4, zIndex: 1 }}
        onClick={openActions}
        aria-controls={open ? "actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <MoreVert color="action" fontSize="medium" />
      </IconButton>
      <Menu
        id="campaign-actions"
        anchorEl={anchorEl}
        open={open}
        onClose={closeActions}
      >
        <MenuItem
          onClick={() =>
            navigate(`/actions/campaigns/edit/${campaign._id}`, {
              state: { id: campaign._id, name: campaign.name },
            })
          }
        >
          Modifier
        </MenuItem>
        <MenuItem onClick={() => onRemoveCampaign(campaign._id)}>
          Supprimer
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`/statistics/${campaign._id}`, {
              state: { id: campaign._id, name: campaign.name },
            })
          }
        >
          Rapport
        </MenuItem>
        <MenuItem onClick={() => setSelectedCampaign(campaign._id)}>
          Prévisualiser
        </MenuItem>
        <MenuItem>
          Activer/Désactiver
          <Switch
            color={campaign.status === 1 ? "success" : "default"}
            checked={!!campaign.status}
            onChange={() => onPatchCampaign(campaign._id, campaign.status)}
            disabled={patchMutation.isLoading}
            edge="end"
          />
        </MenuItem>
      </Menu>
      <Typography variant="h4" marginBottom={1}>
        {campaign.name}
      </Typography>
      <Typography
        component="span"
        variant="body1"
        display="flex"
        alignItems="center"
        justifyItems="center"
      >
        <Money color="action" sx={{ width: 18, marginRight: 1 }} /> Budget
        journalier : {campaign.budget || 0} €
      </Typography>
      <Typography
        component="span"
        variant="body1"
        display="flex"
        alignItems="center"
        justifyItems="center"
      >
        <DateRange color="action" sx={{ width: 18, marginRight: 1 }} /> Date de
        début : {new Date(campaign.startDate).toLocaleDateString("fr-FR")}
      </Typography>
      <Typography
        component="span"
        variant="body1"
        display="flex"
        alignItems="center"
        justifyItems="center"
      >
        <DateRange color="action" sx={{ width: 18, marginRight: 1 }} /> Date de
        fin :{" "}
        {campaign.endDate
          ? new Date(campaign.endtDate).toLocaleDateString("fr-FR")
          : "∞"}
      </Typography>
      {/*<Typography
        variant="body1"
        component="span"
        display="flex"
        alignItems="center"
        justifyItems="center"
      >
        <Visibility color="action" sx={{ width: 18, marginRight: 1 }} /> Views :{" "}
        {campaign.views || 0}
        </Typography> */}
      {/* <Typography
        variant="body1"
        component="span"
        display="flex"
        alignItems="center"
        justifyItems="center"
        mb={1}
      >
        <AdsClick color="action" sx={{ width: 18, marginRight: 1 }} /> Cliques :{" "}
        {campaign.clicks || 0}
        </Typography> */}
      <Chip
        icon={campaign.status === 1 ? <Check /> : <Block />}
        color={campaign.status === 1 ? "success" : "error"}
        label={campaign.status === 1 ? "Actif" : "Inactif"}
        variant="outlined"
        sx={{ marginTop: 2 }}
      />
    </Paper>
  );
};

export default CampaignCard;
