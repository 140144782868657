import { NotificationManager } from "react-notifications";
import store from "../app/store";
import { logout } from "../redux/auth/thunks";

export const internalError = (error: any) => {
  console.error(error);

  if (
    error?.status === 401 ||
    (typeof error?.data === "string" &&
      (error?.data?.indexOf("auth/user-token-expired") > -1 ||
        error?.data?.indexOf("auth/user-not-found") > -1))
  ) {
    store.dispatch(logout());
  } else {
    NotificationManager.error(
      "Une erreur est survenue, veuillz réssayer plus târd"
    );
  }
};
