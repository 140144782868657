import { useAppDispatch } from "../../app/hooks";
import { uploadFile } from "../../redux/fileSlice";

const useUploadFile = () => {
  const dispatch = useAppDispatch();

  return (file: File) => dispatch(uploadFile(file)).unwrap();
};

export default useUploadFile;
