import React from "react";
import {
  Box,
  Button,
  FormHelperText,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Map } from "@mui/icons-material";
import GeoAddressPicker from "./GeoAddressPicker";
import { useField } from "formik";
const FormikGeoLocationPicker = ({
  label = "l'adresse",
  locationName,
  addressName,
  geoJSONName,
  fullAddress = true,
  title = "Votre adresse",
}: {
  label?: string;
  locationName: string;
  addressName: string;
  geoJSONName?: any;
  fullAddress?: boolean;
  title?: string;
}) => {
  const [locationField, locationMeta, locationHelpers] = useField(locationName);
  const [addressField, addressMeta, addressHelpers] = useField(addressName);
  const [,, geoJSONHelpers] = useField(geoJSONName);
  const [open, setOpen] = React.useState(false);

  const addressInvalid = addressMeta.touched && addressMeta.error;
  const locationInvalid = locationMeta.touched && locationMeta.error;

  return (
    <Box>
      <Stack spacing={1} direction="column">
        {addressField.value && (
          <Stack pt={2} direction="column" spacing={2}>
            <Typography variant="body1">{title}</Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#000",
              }}
            >
              {addressField.value}
            </Typography>
          </Stack>
        )}
        <Button variant="text" endIcon={<Map />} onClick={() => setOpen(true)}>
          {label}
        </Button>
        {(addressInvalid || locationInvalid) && (
          <Stack direction="column" alignItems="center" spacing={2}>
            <FormHelperText error>
              {(addressMeta.error || locationMeta.error) as string}
            </FormHelperText>
          </Stack>
        )}
      </Stack>
      <Modal open={open}>
        <>
          <GeoAddressPicker
            onClose={() => setOpen(false)}
            location={locationField.value}
            address={addressField.value}
            onSave={(location, address, geoJSON) => {
              locationHelpers.setValue(location);
              addressHelpers.setValue(address);
              geoJSONHelpers.setValue(geoJSON);
              locationHelpers.setTouched(false, false);
              addressHelpers.setTouched(false, false);
              geoJSONHelpers.setTouched(false, false);
              setOpen(false);
            }}
            fullAddress={fullAddress}
          />
        </>
      </Modal>
    </Box>
  );
};

export default FormikGeoLocationPicker;
