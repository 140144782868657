import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, CircularProgress, DialogActions, Stack } from "@mui/material";

const Confirmation = ({
  open,
  isLoading,
  description = "Êtes-vous sûr vouloir confirmer cette action?",
  onConfirm,
  onClose,
}: {
  description?: string;
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>{description}</DialogContent>
    {!isLoading ? (
      <DialogActions>
        <Button onClick={() => onConfirm()}>Oui</Button>
        <Button variant="contained" onClick={() => onClose()}>
          Non
        </Button>
      </DialogActions>
    ) : (
      <Stack alignItems="center" p={2}>
        <CircularProgress size={24} />
      </Stack>
    )}
  </Dialog>
);

export default Confirmation;
