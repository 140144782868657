import React from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { BoxProps } from "@mui/material";

import BLANK_IMAGE_SQUARE from "../../assets/images/blankimage_square.png";
import BLANK_IMAGE_RECT from "../../assets/images/blankimage_rectangular.png";
// import firebase from "../../lib/firebase";

// eslint-disable-next-line jsx-a11y/alt-text
const RegularImage = (props: React.ComponentProps<"img">) => <img {...props} />;

const FirebaseStorageImage = ({
  reference,
  Component = RegularImage,
  ...props
}: Omit<React.ComponentProps<"img">, "src"> & { ratio?: AspectRatio } & {
  reference: string;
  Component?: any;
  sx?: BoxProps["sx"];
}) => {
  const [downloadURL, setDownloadURL] = React.useState("");
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const storage = getStorage();
    let realRef: any;
    if (reference.search(".jpg") > 0) {
      realRef = reference.replace(".jpg", "_600x600.jpg");
    }

    if (reference.search(".jpeg") > 0) {
      realRef = reference.replace(".jpeg", "_600x600.jpeg");
    }

    if (reference.search(".png") > 0) {
      realRef = reference.replace(".png", "_600x600.png");
    }

    const findURl = async (tries: number) => {
      const fileRef = ref(storage, `public/${realRef}`);
      try {
        const refURL = await getDownloadURL(fileRef);
        setDownloadURL(refURL);
      } catch (err) {
        if (tries - 1) {
          setTimeout(() => {
            findURl(tries - 1);
          }, 1000);
        } else {
          setError(true);
          console.error(err);
        }
      }

      /*firebase
        .storage()
        .ref(`public/${realRef}`)
        .getDownloadURL()
        .then((result) => {
          setDownloadURL(result);
        })
        .catch((err: any) => {
          if (tries - 1) {
            setTimeout(() => {
              findURl(tries - 1);
            }, 1000);
          } else {
            setError(true);
            console.error(err);
          }
        });*/
    };

    findURl(3);
  }, [reference]);

  if (downloadURL) {
    return <Component {...props} src={downloadURL} />;
  }

  if (error) {
    const fallbackImage =
      props.ratio === "1:1" ? BLANK_IMAGE_SQUARE : BLANK_IMAGE_RECT;

    return <Component {...props} src={fallbackImage} />;
  }

  return null;
};

export default FirebaseStorageImage;
