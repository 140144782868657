import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PageActionContext } from "./PageActionContainer";

const PageAction = ({
  title = "",
  subtitle = "",
  children,
}: {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
}) => {
  const { setActionTitle } = React.useContext(PageActionContext);

  React.useEffect(() => {
    setActionTitle(title);

    return () => {
      setActionTitle("");
    };
  }, [setActionTitle, title]);

  return (
    <HelmetProvider>
      <Stack spacing={2} position="relative">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {subtitle && (
          <Typography
            position="absolute"
            top={-18}
            left={52}
            variant="body2"
            color="GrayText"
          >
            {subtitle}
          </Typography>
        )}
        <Box>{children}</Box>
      </Stack>
    </HelmetProvider>
  );
};

export default PageAction;
