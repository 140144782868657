import { configureStore } from "@reduxjs/toolkit";

import { loaderReducer } from "../redux/loaderSlice";
import { authReducer } from "../redux/auth/authSlice";
import authApi from "../redux/authApi";
import { autocompleteApi, geoJSONApi, geocodingApi } from "../redux/geocodingApi";
import campaignsApi from "../redux/campaignsApi";
import campaignAdSetsApi from "../redux/campaignAdSetsApi";
import campaignAdsApi from "../redux/campaignAdsApi";
import filesApi from "../redux/filesApi";
import businessCategoriesApi from "../redux/businessCategoriesApi";
import { customBusinessApi } from "../redux/businessesApi";
import adUserApi from "../redux/adUserApi";
import paymentApi from "../redux/paymentApi";

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    [authApi.reducerPath]: authApi.reducer,
    [adUserApi.reducerPath]: adUserApi.reducer,
    [geocodingApi.reducerPath]: geocodingApi.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [campaignAdSetsApi.reducerPath]: campaignAdSetsApi.reducer,
    [campaignAdsApi.reducerPath]: campaignAdsApi.reducer,
    [businessCategoriesApi.reducerPath]: businessCategoriesApi.reducer,
    [customBusinessApi.reducerPath]: customBusinessApi.reducer,
    [geoJSONApi.reducerPath]: geoJSONApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      authApi.middleware,
      adUserApi.middleware,
      geocodingApi.middleware,
      autocompleteApi.middleware,
      filesApi.middleware,
      businessCategoriesApi.middleware,
      campaignsApi.middleware,
      campaignAdSetsApi.middleware,
      campaignAdsApi.middleware,
      customBusinessApi.middleware,
      geoJSONApi.middleware,
      paymentApi.middleware,
    ]),
});

export default store;
