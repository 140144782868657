import { createSlice } from "@reduxjs/toolkit";
import React, { useRef } from "react";
import { generate } from "shortid";
import { useAppDispatch } from "../app/hooks";

const initialState: {
  loaders: {
    [key: string]: boolean;
  };
} = {
  loaders: {},
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    loadingChanged(state, val) {
      state.loaders = {
        ...state.loaders,
        ...val.payload,
      };
    },
  },
});

export const { loadingChanged } = loaderSlice.actions;

export const loaderReducer = loaderSlice.reducer;

export const useLoadingEffect = (val: boolean) => {
  const dispatch = useAppDispatch();
  const refId = useRef(generate());

  React.useEffect(() => {
    dispatch(
      loadingChanged({
        [refId.current]: val,
      })
    );

    return () => {
      dispatch(
        loadingChanged({
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [refId.current]: false,
        })
      );
    };
  }, [dispatch, val]);
};

export default loaderSlice;
