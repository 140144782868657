import { Paper } from "@mui/material";

import PageAction from "../../components/organisms/PageAction";
import CheckoutForm, { StripeWrapper } from "../../components/atoms/CheckoutForm";
import { useLocation } from "react-router-dom";


const Checkout = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return (
    <PageAction title="Formulaire de paiement">
      <Paper square>
        <StripeWrapper clientSecret={query.get('cs')}>
          <CheckoutForm clientSecret={query.get('cs')}/>
        </StripeWrapper>
      </Paper>
    </PageAction>
  );
};

export default Checkout;
