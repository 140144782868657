import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, MenuItem, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save, PersonRemove } from "@mui/icons-material";
import { Formik } from "formik";
import * as yup from "yup";

import { internalError } from "../../lib/internalError";
import {
  useDeleteAdUserMutation,
  useUpdateAdUserMutation,
} from "../../redux/adUserApi";
import FormikTextField from "../../components/atoms/formik/FormikTextField";
// import FormikPhoneNumberInput from "../../components/atoms/formik/FormikPhoneNumberInput";
import { NotificationManager } from "react-notifications";
import {
  useGetAuthUser,
  useGetFirebaseAuthUser,
} from "../../lib/hooks/useGetAuthUserId";
import PageAction from "../../components/organisms/PageAction";
import Confirmation from "../../components/organisms/Confirmation";

import { auth } from "../../lib/firebase";
import { updateProfile } from "firebase/auth";
import FormikGeoLocationPicker from "../../components/atoms/formik/GeoLocationPicker/FormikGeoLocationPicker";
import FormikSelect from "../../components/atoms/formik/FormikSelect";
import { useGetAllBusinessCategoriesQuery } from "../../redux/businessesApi";

type FormProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  businessProfile: any;
};

const profileSchema = yup.object({
  firstName: yup
    .string()
    .required("Ce champ est requis.")
    .min(3, "Prénom incorrect"),
  lastName: yup
    .string()
    .required("Ce champ est requis.")
    .min(3, "Nom incorrect"),
  email: yup.string().required("Ce champ est requis.").email("Email incorrect"),
  phoneNumber: yup
    .string()
    .required("Ce champ est requis.")
    .test(function (value) {
      if (
        typeof value === "string" &&
        value.match(/^(\+?(\d{1,5}))?(\d{3})(\d{3})(\d{4})$/gm)
      ) {
        return true;
      } else {
        // @ts-ignore
        return this.createError({
          message:
            "Numéro téléphone invalide (Ex: +33611222333, +12481237654, +34600000000)",
          // @ts-ignore
          path: this.path,
        });
      }
    }),
  businessProfile: yup.object().shape({
    name: yup.string().required("Ce champ est requis."),
    email: yup
      .string()
      .required("Ce champ est requis.")
      .email("Email incorrect"),
    address: yup.object().shape({
      location: yup
        .object()
        .test("location", "Veuillez saisir une adresse.", (value) => {
          return value?.longitude && value?.latitude;
        }),
      formatted: yup.string().required("Ce champ est requis."),
    }),
    category: yup.string().required("Ce champ est requis."),
  }),
});

const Profile = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = React.useState<string>();
  const [open, setOpen] = React.useState<boolean>(false);

  const dbUser = useGetAuthUser();
  const firebaseUser = useGetFirebaseAuthUser();
  const businessCategories = useGetAllBusinessCategoriesQuery();

  const [updateUser, mutation] = useUpdateAdUserMutation();
  const [deleteAccount, deleteAccountMutation] = useDeleteAdUserMutation();
  const isLoading = mutation.isLoading;

  const updateFirebaseProfile = useCallback(
    async (displayName: string) => {
      if (firebaseUser) {
        await updateProfile(firebaseUser, { displayName });
        firebaseUser.reload();
        navigate("/");
      }
    },
    [firebaseUser, navigate]
  );

  useEffect(() => {
    if (mutation.isSuccess) {
      if (fullName) {
        updateFirebaseProfile(fullName);
      }
      NotificationManager.success("Le profil a été enregistré avec succès.");
    }

    if (mutation.isError && mutation.error) {
      internalError(mutation.error);
    }

    if (deleteAccountMutation.isSuccess) {
      auth.signOut();
    }
  }, [
    deleteAccountMutation.isSuccess,
    fullName,
    mutation,
    navigate,
    updateFirebaseProfile,
  ]);

  const onProfileSave = useCallback(
    async ({ firstName, lastName, email, businessProfile }: FormProps) => {
      try {
        setFullName(`${firstName} ${lastName}`);
        const user = {
          uid: firebaseUser?.uid,
          firstName,
          lastName,
          email,
          businessProfile: {
            ...businessProfile,
            address: {
              ...businessProfile.address,
              location: [
                businessProfile.address.location.longitude,
                businessProfile.address.location.latitude,
              ],
            },
          },
        };
        updateUser({ id: dbUser._id, data: user });
      } catch (err: any) {
        internalError(err);
      }
    },
    [dbUser, firebaseUser, updateUser]
  );

  const onDeleteAccount = useCallback(async () => {
    try {
      deleteAccount({ id: dbUser._id });
    } catch (err) {
      console.error("onDeleteAccount: ", err);
    }
  }, [dbUser, deleteAccount]);

  return (
    <PageAction title="Profil">
      <Paper square>
        <Formik<FormProps>
          validationSchema={profileSchema}
          enableReinitialize={!!dbUser?._id}
          initialValues={{
            firstName: dbUser?.firstName || "",
            lastName: dbUser?.lastName || "",
            email: dbUser?.email || "",
            phoneNumber: dbUser?.phoneNumber || firebaseUser?.phoneNumber || "",
            businessProfile: {
              name: dbUser?.businessProfile?.name || "",
              address: {
                formatted: dbUser?.businessProfile?.address?.formatted || "",
                location: dbUser?.businessProfile?.address?.location
                  ? {
                      longitude: dbUser?.businessProfile?.address?.location[0],
                      latitude: dbUser?.businessProfile?.address?.location[1],
                    }
                  : {},
              },
              category: dbUser?.businessProfile?.category || "",
              email: dbUser?.businessProfile?.email || "",
            },
          }}
          onSubmit={onProfileSave}
        >
          {({ handleSubmit }) => (
            <Stack px={2} spacing={3} direction="column" paddingTop={5}>
              <Stack spacing={1}>
                <Typography variant="h4">
                  Informations de l'utilisateur
                </Typography>
              </Stack>
              <Stack spacing={1} paddingBottom={1}>
                <FormikTextField name="firstName" label="Prénom" />
                <FormikTextField name="lastName" label="Nom" />
                <FormikTextField name="email" label="Email" />
                <FormikTextField
                  name="phoneNumber"
                  label="Numéro de téléphone mobile"
                  disabled={true}
                />
                <FormikTextField
                  name="businessProfile.name"
                  label="Nom de l'établissement"
                />
                <FormikGeoLocationPicker
                  label="Adresse"
                  locationName="businessProfile.address.location"
                  addressName="businessProfile.address.formatted"
                />
                <FormikSelect
                  name="businessProfile.category"
                  label="Catégorie d'établissement"
                >
                  {businessCategories.data?.map((businessesCategory) => (
                    <MenuItem
                      key={businessesCategory._id}
                      value={businessesCategory._id}
                    >
                      {businessesCategory.name}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikTextField
                  name="businessProfile.email"
                  label="Email de l'établissement"
                />
              </Stack>
              <LoadingButton
                loading={isLoading || mutation.isLoading}
                disabled={isLoading || mutation.isLoading}
                variant="outlined"
                color="primary"
                onClick={
                  isLoading || mutation.isLoading
                    ? undefined
                    : () => handleSubmit()
                }
                startIcon={<Save />}
              >
                Sauvegarder
              </LoadingButton>
            </Stack>
          )}
        </Formik>
        <Stack paddingBottom={5} paddingTop={2}>
          <Confirmation
            isLoading={deleteAccountMutation.isLoading}
            open={open}
            onConfirm={onDeleteAccount}
            onClose={() => setOpen(false)}
            description="La suppression de votre compte est définitive. Si vous êtes sûr de vouloir supprimer votre compte, cliquez sur 'Oui' ci-dessous. Votre compte et toutes ses données seront supprimés de façon permanente."
          />
          {dbUser?.email && (
            <Button
              variant="text"
              color="error"
              startIcon={<PersonRemove />}
              onClick={() => setOpen(true)}
            >
              Supprimer le compte
            </Button>
          )}
        </Stack>
      </Paper>
    </PageAction>
  );
};

export default Profile;
