import React from "react";
import * as yup from "yup";
import { endOfDay, isAfter, isDate, isPast } from "date-fns";

import FormikTextField from "../../../components/atoms/formik/FormikTextField";
import FormikDatePicker from "../../../components/atoms/formik/FormikDatePicker";

export const stepOneSchema = {
  name: yup.string().required("Ce champ est requis."),
  startDate: yup
    .date()
    .required("Ce champ est requis.")
    .test(
      "testStartDate",
      "Date doit être dans le futur",
      function (value, context) {
        if (value && !context.parent.id && isDate(value)) {
          return !isPast(endOfDay(value));
        }
        return true;
      }
    ),
  endDate: yup
    .date()
    .nullable()
    // .required('Champ obligatoire')
    .test(
      "testEndDate",
      "Date doit être après la date début",
      function (value) {
        if (value && isDate(value)) {
          return isAfter(value, this.parent.startDate);
        }
        return true;
      }
    ),
  budget: yup
    .number()
    .required("Ce champ est requis.")
    .test('valid-budget', '', function(value) {
      const { path, parent, createError }: any = this;
      if (value &&  value < parent.minBudget) {
        return createError({
          path,
          message: `Le budget doit être supérieur ou égal à ${parent.minBudget}€`,
        });
      }
      return true;
    })
};

const StepOne = ({ values, setFieldValue, minBudget }: any) => {

  React.useEffect(() => {
    setFieldValue("minBudget", minBudget);
    if (!values.id) {
      setFieldValue("budget", minBudget);
    }
  }, [values, minBudget, setFieldValue])

  return (
    <>
      <FormikTextField name="name" label="Nom" />
      <FormikDatePicker name="startDate" label="Date de début" />
      <FormikDatePicker name="endDate" label="Date de fin" />
      <FormikTextField
        type="number"
        name="budget"
        label={`Budget journalier (${minBudget}€ munimum)`}
      />
      <FormikTextField type="hidden" name="minBudget" sx={{ zIndex: -1 }} />
    </>
  );
};

export default StepOne;
