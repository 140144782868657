import {Link as RouterLink} from 'react-router-dom';

import {Button, ButtonProps, Link} from '@mui/material';

type SettingsButtonProps = {to?: string} & ButtonProps;

const SettingsButtonBase = ({to, ...props}: SettingsButtonProps) => (
  <Button
    sx={{
      justifyContent: 'flex-start',
      color: 'inherit',
      textTransform: 'none',
      '.MuiButton-startIcon': {
        marginRight: theme => theme.spacing(2),
      },
    }}
    {...props}
  />
);

const SettingsButton = ({to, ...props}: SettingsButtonProps) => {
  if (to) {
    return (
      <Link
        sx={{
          '&, &:active': {
            textDecoration: 'none',
            color: 'inherit',
          },
        }}
        component={RouterLink}
        to={to}>
        <SettingsButtonBase {...props} />
      </Link>
    );
  } else {
    return <SettingsButtonBase {...props} />;
  }
};

export default SettingsButton;
