import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { authBaseQuery } from "./apiBaseQuery";

export const customBusinessApi = createApi({
  reducerPath: "customBusinessApi",
  baseQuery: authBaseQuery,
  tagTypes: ["Business"],
  endpoints(build) {
    return {
      getBusinesses: build.query({
        query: ({
          userLocation,
          limit = 5000,
          // skip,
          distance = 50,
        }) => ({
          url: "/getBusinesses",
          method: "get",
          params: {
            userLocation: `${userLocation.longitude},${userLocation.latitude}`,
            distance,
            limit,
            // skip,
          },
        }),
      }),
      getAllBusinessCategories: build.query<BusinessCategory[], void>({
        query: () => ({
          url: "/getBusinessCategories",
          method: "get",
          keepUnusedDataFor: 1000000,
        }),
      }),
    };
  },
});

export const { useGetAllBusinessCategoriesQuery, useGetBusinessesQuery } =
  customBusinessApi;
