import { Box, Typography } from "@mui/material";
import React from "react";

const EmptyList = ({ message }: { message: string }) => (
  <Box py={20} px={5}>
    <Typography variant="body1" align="center">
      {message}
    </Typography>
  </Box>
);

export default EmptyList;
