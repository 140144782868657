import React from "react";
import { Field, FieldProps } from "formik";
import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

const FormikDatePicker: React.FC<
  {
    name: string;
  } & Partial<Omit<DatePickerProps<any, any>, "value" | "renderInput">>
> = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <DatePicker
          {...props}
          value={field.value}
          onChange={(newValue: any) => {
            if (typeof props.onChange === "function") {
              props.onChange(newValue);
            }
            form.setFieldValue(name, newValue);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={!!form.errors[name]}
              helperText={
                form.touched[name] && form.errors[name]
                  ? (form.errors[name] as string)
                  : undefined
              }
            />
          )}
        />
      )}
    </Field>
  );
};

export default FormikDatePicker;
