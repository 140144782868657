import Dialog from "@mui/material/Dialog";
import AlertContext from "./AlertContext";
import React, { useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import { Box, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const AlertProvider = ({ children }: { children: JSX.Element }) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState<JSX.Element | string>("");
  const [title, setTitle] = React.useState("");

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [open]);
  return (
    <>
      <AlertContext.Provider
        value={{
          message,
          title,
          showAlert: ({
            title,
            message,
          }: {
            message: string | JSX.Element;
            title: string;
          }) => {
            setTitle(title);
            setMessage(message);
            setOpen(true);
          },
        }}
      >
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {title}
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box py={2}>{message}</Box>
          </DialogContent>
        </Dialog>
        {children}
      </AlertContext.Provider>
    </>
  );
};

export default AlertProvider;
