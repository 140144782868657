import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import RoundIconButton from "../atoms/RoundIconButton";
import { ArrowBack } from "@mui/icons-material";

export const PageActionContext = React.createContext<{
  setActionTitle: (title: string) => void;
  actionTitle: string;
}>({
  setActionTitle: () => {},
  actionTitle: "",
});

const PageActionContainer = () => {
  const navigate = useNavigate();
  const [actionTitle, setActionTitle] = React.useState<string>("");

  return (
    <PageActionContext.Provider
      value={{
        setActionTitle,
        actionTitle,
      }}
    >
      <Box p={2} sx={{ backgroundColor: "white", flex: 1 }} position="relative">
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(2),
            left: (theme) => theme.spacing(1),
          }}
        >
          <RoundIconButton onClick={() => navigate(-1)} color="secondary">
            <ArrowBack fontSize="medium" />
          </RoundIconButton>
          <Typography variant="h5">{actionTitle}</Typography>
        </Stack>
        <Box pt={8}>
          <Outlet />
        </Box>
      </Box>
    </PageActionContext.Provider>
  );
};

export default PageActionContainer;
