import React from "react";
import { Field, FieldProps, getIn } from "formik";
import { TextField, TextFieldProps } from "@mui/material";

const FormikTextField: React.FC<{ name: string } & TextFieldProps> = ({
  name,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <TextField
          {...field}
          {...props}
          error={
            Boolean(getIn(form.touched, name) && getIn(form.errors, name))
            // !!form.errors[name]
          }
          helperText={
            getIn(form.touched, name) && getIn(form.errors, name)
            // form.touched[name] && form.errors[name] ? (form.errors[name] as string) : undefine
          }
        />
      )}
    </Field>
  );
};

export default FormikTextField;
