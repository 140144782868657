// import firebase from "firebase/compat/app";
// import "firebase/compat/storage";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

import firebaseConfig from "../firebase.json";

const app = initializeApp(firebaseConfig);

// getAnalytics(app);

export const auth = getAuth(app);

auth.useDeviceLanguage();

// export default firebase;
