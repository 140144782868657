import React from "react";
import { NotificationContainer } from "react-notifications";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "react-notifications/lib/notifications.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useJsApiLoader } from "@react-google-maps/api";

import "./App.css";
import AppRoutes from "./AppRoutes";
import store from "./app/store";
import config from "./config.json";
import { AlertProvider } from "./lib/Alert";
import { theme } from "./appTheme";
// import "./lib/stripe/stripePromise";

function App() {
  useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleMapsApiKey,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </AlertProvider>
          <NotificationContainer />
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;
