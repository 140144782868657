import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["AuthUser"],
  endpoints(build) {
    return {
      getAuthUser: build.query({
        query: ({ uid }) => ({
          url: `/ad-users?$limit=1&uid=${uid}`,
          method: "get",
          params: { uid },
        }),
        providesTags: (result) =>
          result
            ? [
                { type: "AuthUser", id: result._id },
                { type: "AuthUser", id: "LIST" },
              ]
            : [{ type: "AuthUser", id: "LIST" }],
      }),
    };
  },
});

export const { useGetAuthUserQuery } = authApi;

export default authApi;
