import React from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FirebaseStorageImage from "../components/atoms/FirebaseStorageImage";
import AspectRatioImage from "../components/atoms/AspectRatioImage";
import RoundTextIconButton from "../components/atoms/RoundButton";
import { Visibility } from "@mui/icons-material";
import { theme } from "../appTheme";

const Preview = ({
  ad,
  hidePreviewButton = false,
  open = false,
  callback = null,
}: any) => {
  const [toggle, setToggle] = React.useState(false);

  const _onToggle = React.useCallback(() => {
    setToggle(!toggle);
    if (callback && typeof callback === "function") {
      callback();
    }
  }, [callback, toggle]);

  return (
    <>
      <Dialog fullScreen open={open || toggle}>
        <AppBar sx={{ position: "relative", marginBottom: 1 }} color="default">
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => _onToggle()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Aperçu
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          id="container"
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            maxWidth: "600px",
            p: 1,
            mx: "auto",
            "@media (min-width: 600px)": {
              border: "20px solid #000",
              borderRadius: "10px",
              boxSizing: "border-box",
              overflowY: "auto",
              px: 1,
              my: 1,
              padding: 2,
            },
          }}
        >
          <Paper variant="outlined">
            {ad.media && (
              <img
                src={URL.createObjectURL(ad.media)}
                alt="preview"
                style={{ width: "100%", height: "300px" }}
              />
            )}
            {ad?.mediaRef && !ad.media && (
              <FirebaseStorageImage
                Component={AspectRatioImage}
                reference={ad?.mediaRef}
                ratio={ad?.imageAspectRatio}
              />
            )}
            <Stack sx={{ padding: 1.5 }}>
              <Typography variant="body2" align="right" color="grey.400">
                Sponsorisé
              </Typography>
              <Typography
                variant="h5"
                align="left"
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {ad?.headline}
              </Typography>
              <Typography variant="body1" align="left" color={"GrayText"}>
                {ad.primaryText}
              </Typography>
              <Typography variant="body2" align="left" color={"GrayText"}>
                {ad?.description}
              </Typography>
            </Stack>
          </Paper>
        </Box>
      </Dialog>

      {!hidePreviewButton && (
        <RoundTextIconButton
          text="Prévisualiser"
          icon={<Visibility />}
          color="primary"
          sx={{ position: "fixed", bottom: 110, right: theme.spacing(2) }}
          onClick={() => _onToggle()}
        />
      )}
    </>
  );
};

export default Preview;
