import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";

const filesApi = createApi({
  reducerPath: "filesApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["Files"],
  endpoints(build) {
    return {
      createFile: build.mutation<AppFile, { reference: string; name: string }>({
        query: (data) => ({ url: "/file", method: "post", data }),
        invalidatesTags: [{ type: "Files", id: "LIST" }],
      }),
      deleteFile: build.mutation<void, string>({
        query: (id) => ({ url: `/file/${id}`, method: "delete" }),
        invalidatesTags: [{ type: "Files", id: "LIST" }],
      }),
      getFiles: build.query<PaginateResult<Category>, PaginateParams>({
        query: () => ({
          url: "/file",
          method: "get",
        }),
      }),
    };
  },
});

export const { useCreateFileMutation } = filesApi;

export default filesApi;
