import { Box, BoxProps } from "@mui/material";

const getPaddingBottom = (ratio: AspectRatio) => {
  switch (ratio) {
    case "1:1":
      return "100%";
    case "16:9":
      return "56.25%";
    case "9:16":
      return "88.885%";
    case "1.91:1":
      return "52.36%";
    case "10:4":
      return "40%";
    default:
      return "100%";
  }
};

const AspectRatioImage = ({
  src,
  ratio,
  sx,
  style,
}: {
  src: string;
  ratio: AspectRatio;
  sx?: BoxProps["sx"];
  style?: any;
}) => {
  return (
    <Box
      style={style}
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        resize: "horizontal",
        ...(ratio === "9:16"
          ? {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : {}),
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${src})`,
          width: ratio === "9:16" ? "50%" : "100%",
          paddingBottom: getPaddingBottom(ratio),
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      />
    </Box>
  );
};

export default AspectRatioImage;
