import { Field, FieldProps, getIn } from "formik";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";
import React from "react";

const FormikSelect = ({
  name,
  label,
  ...props
}: SelectProps & { name: string }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select {...field} label={label} {...props} />
          {getIn(form.touched, name) && getIn(form.errors, name) && (
            <FormHelperText error>{getIn(form.errors, name)}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default FormikSelect;
