import * as yup from "yup";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";

import FormikTextField from "../../../components/atoms/formik/FormikTextField";
import FormikFileInput from "../../../components/atoms/formik/FormikFileInput";
import FirebaseStorageImage from "../../../components/atoms/FirebaseStorageImage";
import AspectRatioImage from "../../../components/atoms/AspectRatioImage";

export const stepThreeSchema = {
  // adName: yup.string().required("Ce champ est requis."),
  primaryText: yup
    .string()
    .max(200, "Ce champ ne peut pas dépasser 200 caractères."), // .required('Champ obligatoire.'),
  headline: yup
    .string()
    .max(200, "Ce champ ne peut pas dépasser 200 caractères."), // .required('Champ obligatoire.'),
  description: yup
    .string()
    .max(1000, "Ce champ ne peut pas dépasser 1000 caractères."), // .required('Champ obligatoire.'),
  websiteURL: yup
    .string()
    .matches(
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
      "URL invalide. Saisir une URL qui commence par http ou https."
    ),
  media: yup.string(), // .test("media", "Champ obligatoire.", (_value: any, context) => {return _value ? true : !!context.parent.id;}),
  imageAspectRatio: yup.string(),
};

const StepThree = ({ setFieldValue, values, campaign }: any) => {
  return (
    <>
      {campaign.media?.reference && !values.media && (
        <Grid container sm={6} xs={12} alignSelf="center" item>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <FirebaseStorageImage
                Component={AspectRatioImage}
                reference={campaign.media?.reference as string}
                ratio={values.imageAspectRatio}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <FormikFileInput name="media" maxSize={5} />
      <RadioGroup
        row
        name="imageAspectRatio"
        value={values.imageAspectRatio}
        onChange={(e) => setFieldValue("imageAspectRatio", e.target.value)}
      >
        <FormControlLabel value="16:9" control={<Radio />} label="16:9" />
        <FormControlLabel value="1.91:1" control={<Radio />} label="1.91:1" />
        <FormControlLabel value="1:1" control={<Radio />} label="1:1" />
        <FormControlLabel value="9:16" control={<Radio />} label="9:16" />
        <FormControlLabel value="10:4" control={<Radio />} label="10:4" />
      </RadioGroup>
      {/* <FormikTextField name="adName" label="Nom" /> */}
      <FormikTextField
        name="primaryText"
        label="Texte 1"
        placeholder="Veuillez saisir un titre (optionnel, 200)"
      />
      <FormikTextField
        name="headline"
        label="Texte 2"
        placeholder="Veuillez saisir un sous titre (optionnel, 200)"
      />
      <FormikTextField
        name="description"
        label="Description"
        placeholder="Veuillez saisir une description (optionnel, 1000)"
      />
      <FormikTextField
        name="websiteURL"
        label="Site web (ex: https://www.fluxence.com)"
        placeholder="Site web (ex: https://www.fluxence.com)"
      />
    </>
  );
};

export default StepThree;
