import { useGetAuthUserQuery } from "../../redux/authApi";
import { auth } from "../firebase";

const useGetAuthUserId = () => {
  const authUser = useGetAuthUser();

  return { id: authUser?._id, uid: authUser?.uid || auth.currentUser?.uid };
};

export const useGetAuthUser = () => {
  const query = useGetAuthUserQuery(
    { uid: auth.currentUser?.uid },
    { skip: !auth.currentUser?.uid, refetchOnMountOrArgChange: true }
  );
  return query.isSuccess ? query.data.data[0] : null;

  /*return {
    userId: query.isSuccess ? query.data.data[0]?._id : null,
    refetch: query.refetch,
  };*/
  // return query.isSuccess ? query.data._id : null;
};

export const useGetFirebaseAuthUser = () => {
  return auth.currentUser;
};

export default useGetAuthUserId;
