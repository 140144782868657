import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  PlaceAutocompleteResponseData,
  PlaceDetailsResponseData,
} from "@googlemaps/google-maps-services-js";
import { authBaseQuery } from "./apiBaseQuery";

export const autocompleteApi = createApi({
  reducerPath: "autocompleteApi",
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      autoCompletePlaces: build.query<PlaceAutocompleteResponseData, string>({
        query: (search) => ({
          url: "/getPlaces",
          method: "get",
          params: {
            input: search,
          },
        }),
      }),
    };
  },
});

export const geocodingApi = createApi({
  reducerPath: "geocodingApi",
  baseQuery: authBaseQuery,
  endpoints(build) {
    return {
      getPlaceById: build.query<PlaceDetailsResponseData, string>({
        query: (placeId) => ({
          url: "/getPlace",
          method: "get",
          params: {
            placeId,
          },
        }),
      }),
    };
  },
});

export const geoJSONApi = createApi({
  reducerPath: "geoJSONApi",
  baseQuery: fetchBaseQuery({ baseUrl: 'https://nominatim.openstreetmap.org/' }),
  endpoints(build) {
    return {
      geoJSON: build.query({
        query: (search) => ({
          url: "search.php",
          method: "get",
          params: {
            q: `${search}`,
            polygon_geojson: "1",
            format: "json",
          },
        }),
      }),
    };
  },
});

export const { useAutoCompletePlacesQuery, useLazyAutoCompletePlacesQuery } =
  autocompleteApi;

export const { useGetPlaceByIdQuery, useLazyGetPlaceByIdQuery } = geocodingApi;

export const { useGeoJSONQuery } = geoJSONApi;
