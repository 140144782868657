import {Helmet} from 'react-helmet';
import {
  AccountCircle,
  Logout,
  Payments,
} from '@mui/icons-material';
import {Breadcrumbs, Divider, Stack, Typography} from '@mui/material';

import UserPage from '../../components/organisms/UserPage';
import SettingsButton from './SettingsButton';

const Settings = () => {
  return (
    <UserPage
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="h6" color="text.primary">
            Paramètres
          </Typography>
        </Breadcrumbs>
      }>
      <Helmet>
        <title>Paramètres</title>
      </Helmet>
      <Stack spacing={1}>
        <SettingsButton
          to="/actions/profile"
          startIcon={<AccountCircle />}
          variant="text" fullWidth>
          Profil
        </SettingsButton>
        <Divider />
        <SettingsButton
          to="/actions/payments"
          startIcon={<Payments />}
          variant="text" fullWidth>
          Paiement
        </SettingsButton>
        <Divider />
        <SettingsButton to="/logout" startIcon={<Logout />} variant="text" fullWidth>
          Déconnexion
        </SettingsButton>
      </Stack>
    </UserPage>
  );
};

export default Settings;
