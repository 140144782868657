import React from "react";
import { Button, Stack } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import useUploadFile from "../../lib/hooks/useUploadFile";
import { internalError } from "../../lib/internalError";
import PageAction from "../../components/organisms/PageAction";
import {
  useCreateCampaignMutation,
  useGetCampaignByIdQuery,
  useUpdateCampaignMutation,
} from "../../redux/campaignsApi";
import StepOne, { stepOneSchema } from "./FormSteps/StepOne";
import StepTwo, { stepTwoSchema } from "./FormSteps/StepTwo";
import StepThree, { stepThreeSchema } from "./FormSteps/StepThree";
import useGetAuthUserId from "../../lib/hooks/useGetAuthUserId";
import Preview from "../preview";
import { useGetPreferencesQuery } from "../../redux/campaignAdsApi";

const steps = [
  {
    title: "Paramètrer la campagne",
    component: StepOne,
    schema: stepOneSchema,
    description: "Informations de la campagne",
  },
  {
    title: "Configurer une annonce", // "Créer un groupe d'annonces",
    component: StepTwo,
    schema: stepTwoSchema,
    description: "Lieux d'affichage et pages de destination",
  },
  {
    title: "Saisir une annonce",
    component: StepThree,
    schema: stepThreeSchema,
    description: "Contenu de la campagne",
  },
];

const CampaignForm = () => {
  const location = useLocation();
  const uploadFile = useUploadFile();
  const navigate = useNavigate();
  const { id } = useParams();

  const query = useGetCampaignByIdQuery({ id }, { skip: !id });
  const owner = useGetAuthUserId();
  const preferenceQuery = useGetPreferencesQuery({params: {name: "dailyBudget"}});
  const preferencesData = preferenceQuery.isSuccess ? preferenceQuery.data?.data[0] : undefined;

  const [activeStep, setActiveStep] = React.useState(0);
  const [processing, setProcessing] = React.useState(false);

  const [create, createMutation] = useCreateCampaignMutation();
  const [update, updateMutation] = useUpdateCampaignMutation();

  React.useEffect(() => {
    const responseError = createMutation.error || (updateMutation.error as any);
    if (responseError) {
      if (responseError?.data?.error?.code === "") {
        NotificationManager.error("Le nom déjà utilisé");
      } else {
        internalError(createMutation.error || updateMutation.error);
      }
    }
    if (createMutation.isSuccess || updateMutation.isSuccess) {
      NotificationManager.success("La campagne a été sauvegardée avec succès");
      navigate("/");
    }
  }, [createMutation, navigate, updateMutation]);

  const nextStep = () => {
    if (!(activeStep === steps.length - 1)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const backStep = () => {
    if (!(activeStep === 0)) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const onSubmit = React.useCallback(
    async ({
      name,
      startDate,
      endDate,
      budget,
      // adSetName,
      address,
      coordinate,
      radius,
      placements,
      categories,
      // adName,
      media,
      imageAspectRatio,
      primaryText,
      headline,
      description,
      websiteURL,
      geoJSON,
    }: any) => {
      try {
        setProcessing(true);
        const data: any = {
          campaign: {
            name,
            startDate,
            endDate,
            budget,
            owner: owner?.id,
          },
          adSet: {
            name: `${name} - adSet`,
            address: [address],
            coordinate,
            radius,
            placements: placements.length ? placements : null,
            categories: categories.length ? categories : null,
            geometry: geoJSON,
          },
          ad: {
            name: `${name} - ad`,
            primaryText,
            headline,
            description,
            websiteURL,
            imageAspectRatio,
          },
          ids: {
            campaign: query?.data?._id,
            adSet: query?.data?.adSet?._id,
            ad: query?.data?.ad?._id,
          },
        };
        if (media) {
          const mediaFile = await uploadFile(media);
          data.ad.media = mediaFile._id;
        }
        query?.data?._id
          ? await update({ id: query?.data?._id, data })
          : await create(data);
      } catch (error) {
        console.error("Form campaign error: ", error);
      } finally {
        setProcessing(false);
      }
    },
    [query?.data, create, update, uploadFile, owner]
  );

  return (
    <PageAction
      title={
        location?.state?.id
          ? `Modifier la campagne : ${location?.state?.name}`
          : "Créer une nouvelle campagne"
      }
      subtitle={`+ ${steps[activeStep].description}`}
    >
      <Stepper activeStep={activeStep} alternativeLabel sx={{ marginTop: 5 }}>
        {steps.map(({ title }) => (
          <Step key={title}>
            <StepLabel>{title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Formik
        validationSchema={yup.object().shape(steps[activeStep].schema)}
        onSubmit={async (values) =>
          activeStep === steps.length - 1 ? onSubmit(values) : nextStep()
        }
        initialValues={{
          id,
          name: query?.data?.name || "",
          budget: query?.data?.budget || preferencesData?.preferences?.value || 5,
          minBudget: preferencesData?.preferences?.value || 5,
          startDate: query?.data?.startDate
            ? new Date(query?.data?.startDate)
            : new Date(),
          endDate: query?.data?.endDate ? new Date(query?.data?.endDate) : null,
          // adSetName: query?.data?.adSet?.name || "",
          address: query?.data?.adSet?.address[0] || "",
          coordinate: query?.data?.adSet?.coordinate || undefined,
          geoJSON: query?.data?.adSet?.geometry || null,
          radius: query?.data?.adSet?.radius || 0,
          placements: query?.data?.adSet?.placements || [],
          categories: query?.data?.adSet?.categories || [],
          // adName: query?.data?.ad?.name || "",
          media: "",
          imageAspectRatio: query?.data?.ad?.imageAspectRatio || "16:9",
          primaryText: query?.data?.ad?.primaryText || "",
          headline: query?.data?.ad?.headline || "",
          description: query?.data?.ad?.description || "",
          websiteURL: query?.data?.ad?.websiteURL || "",
        }}
        enableReinitialize={!!id}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Stack direction="column" spacing={2} mt={3}>
            {React.createElement(steps[activeStep].component, {
              setFieldValue,
              values,
              campaign: query?.data || {},
              minBudget: preferencesData?.preferences?.value || 5,
            })}
            <Stack
              direction="row"
              spacing={2}
              sx={{ position: 'fixed', bottom: 18, width: "96%" }}
            >
              <Button
                onClick={backStep}
                variant="outlined"
                disabled={activeStep === 0}
                fullWidth
              >
                &#60; Retour
              </Button>
              <LoadingButton
                onClick={() => handleSubmit()}
                loading={
                  processing ||
                  createMutation.isLoading ||
                  updateMutation.isLoading
                }
                disabled={
                  processing ||
                  createMutation.isLoading ||
                  updateMutation.isLoading
                }
                variant="contained"
                color="primary"
                fullWidth
              >
                {activeStep === steps.length - 1
                  ? "Sauvegarder"
                  : "Suivant \u003E"}
              </LoadingButton>
            </Stack>
            {activeStep === steps.length - 1 && (
              <Preview
                ad={{
                  ...values,
                  mediaRef: query?.data?.media?.reference || "",
                }}
              />
            )}
          </Stack>
        )}
      </Formik>
    </PageAction>
  );
};

export default CampaignForm;
