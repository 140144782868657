import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";

const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["paymentApi"],
  endpoints(build) {
    return {
      getInvoice: build.query({
        query: ({ user }) => ({
          url: '/ads-invoices',
          method: "get",
          params: { user },
        }),
        transformResponse: (response: any) => {
          if (response.length > 0) {
            return response[0];
          }

          return null;
        },
        providesTags: ["paymentApi"]
      }),
      getUserBillingPortal: build.query({
        query: (id: string = '') => ({
          url: `/ads-invoices/0?userId=${id}&mode=billingPortal`,
          method: 'get',
          keepUnusedDataFor: 1000000,
        }),
        providesTags: ['paymentApi'],
      }),
    };
  },
});

export const {
    useGetInvoiceQuery,
    useLazyGetInvoiceQuery,
    useGetUserBillingPortalQuery,
} = paymentApi;

export default paymentApi;
