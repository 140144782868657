import React, { useCallback } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  getAdditionalUserInfo,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { NotificationManager } from "react-notifications";

import { auth } from "../../lib/firebase";
import {
  useCreateAdUserMutation,
  useGetUserByPhoneNumberQuery,
} from "../../redux/adUserApi";
import Logo from "../../assets/images/logo-lg.png";
import FormikTextField from "../../components/atoms/formik/FormikTextField";
import FormikPhoneNumberInput from "../../components/atoms/formik/FormikPhoneNumberInput";

type FormProps = {
  phoneNumber: string;
  opt: string;
};

const validationSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("Ce champ est requis.")
    .test(function (value) {
      if (
        typeof value === "string" &&
        value.match(/^(\+?(\d{1,5}))?(\d{3})(\d{3})(\d{4})$/gm)
      ) {
        return true;
      } else {
        // @ts-ignore
        return this.createError({
          message:
            "Numéro téléphone invalide (Ex: +33611222333, +12481237654, +34600000000)",
          // @ts-ignore
          path: this.path,
        });
      }
    }),
  opt: yup
    .string()
    .matches(/^[0-9]+$/, "Il ne doit y avoir que des chiffres")
    .min(6, "Doit contenir exactement 6 chiffres")
    .max(6, "Doit contenir exactement 6 chiffres")
    .when("phoneNumber", {
      is: true,
      then: yup.string().required("Ce champ est requis."),
    }),
});

const Login = () => {
  const [recaptchaVerifier, setRecaptchaVerifier] = React.useState<any>(null);
  const [confirmationResult, setConfirmationResult] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = React.useState<string>("");

  const [createAdUser, mutation] = useCreateAdUserMutation();
  const user = useGetUserByPhoneNumberQuery(
    { phoneNumber: userPhoneNumber },
    { skip: !userPhoneNumber, refetchOnMountOrArgChange: true }
  );

  React.useEffect(() => {
    const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });
    setRecaptchaVerifier(verifier);
  }, []);

  const signIn = useCallback(
    async ({ phoneNumber }: any) => {
      try {
        const confirmation = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          recaptchaVerifier
        );
        setUserPhoneNumber(phoneNumber);
        setConfirmationResult(confirmation.verificationId);
        return confirmation;
      } catch (err: any) {
        if (err.code === "auth/invalid-phone-number") {
          throw new Error("Le numéro de téléphone saisi est incorrect.");
        }
        if (err.code === "auth/phone-number-already-exists") {
          throw new Error(
            "Ce numéro de téléphone est déjà utilisé par un autre compte."
          );
        }
        console.log("confirmation: ", err);
        throw new Error(err);
      }
    },
    [recaptchaVerifier]
  );

  const onSignupAndConfirmation = useCallback(
    async ({ phoneNumber, opt }: FormProps) => {
      try {
        const credential = PhoneAuthProvider.credential(
          confirmationResult,
          opt
        );
        const userInfo = await signInWithCredential(auth, credential);
        const AdditionalUserInfo = getAdditionalUserInfo(userInfo);

        if (AdditionalUserInfo?.isNewUser || !user.data) {
          const displayName = userInfo.user.displayName?.split(" ") || [];
          createAdUser({
            uid: userInfo.user.uid,
            firstName: displayName?.length ? displayName[0] : "",
            lastName:
              displayName?.length && displayName?.length > 1
                ? displayName[displayName.length - 1]
                : "",
            email: userInfo.user.email || "",
            phoneNumber,
          });
        }
      } catch (err: any) {
        if (err.code === "auth/invalid-verification-code") {
          throw new Error(
            "Veuillez vérifier le code reçu par SMS et réessayer."
          );
        }
        throw new Error(err);
      }
    },
    [confirmationResult, createAdUser, user]
  );

  const onSubmit = useCallback(
    async (values: any) => {
      setIsLoading(true);
      try {
        if (!confirmationResult) {
          await signIn(values);
        }

        if (values.opt) {
          await onSignupAndConfirmation(values);
        }
      } catch (err: any) {
        console.error("[Error] onSubmit: ", err);
        NotificationManager.error(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [confirmationResult, onSignupAndConfirmation, signIn]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Paper sx={{ width: "85%" }} square>
        <Formik<FormProps>
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange
          initialValues={{
            phoneNumber: "",
            opt: "",
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Stack py={6} px={2} spacing={2} direction="column">
              <Stack spacing={1} alignItems="center">
                <Box
                  component="img"
                  sx={{ height: 64 }}
                  alt="FluXence ads"
                  src={Logo}
                />
                <Typography variant="h3">FluXence Ads</Typography>
              </Stack>
              <Stack spacing={1}>
                <FormikPhoneNumberInput
                  name="phoneNumber"
                  label="Numéro de téléphone mobile"
                />
                {confirmationResult && (
                  <>
                    <Typography
                      variant="body2"
                      color="GrayText"
                      paddingLeft={0.5}
                      paddingBottom={0.1}
                    >
                      Saisissez le code OTP envoyé par SMS au numéro que vous
                      avez fourni.
                    </Typography>
                    <FormikTextField
                      name="opt"
                      label="Saisissez votre code ici"
                    />
                  </>
                )}
              </Stack>
              <LoadingButton
                loading={isLoading || mutation.isLoading}
                disabled={isLoading || mutation.isLoading}
                variant="contained"
                color="primary"
                onClick={
                  isLoading || mutation.isLoading
                    ? undefined
                    : () => handleSubmit()
                }
              >
                {confirmationResult ? "Valider" : "Suivant \u003E"}
              </LoadingButton>
              <div id="recaptcha-container"></div>
            </Stack>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default Login;
