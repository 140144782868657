import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";

const campaignsApi = createApi({
  reducerPath: "campaignsApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["Campaign"],
  endpoints(build) {
    return {
      getCampaigns: build.query({
        query: ({
          $limit,
          $skip,
          sortField = "createdAt",
          sortOrder = -1,
          search = "",
          owner,
        }) => {
          let url = `/campaigns?owner=${owner}&$limit=${$limit}&$skip=${$skip}&$sort[${sortField}]=${sortOrder}`;
          if (search) {
            url = `${url}&search=${search}`;
          }
          return {
            url,
            method: "get",
          };
        },
        providesTags: ["Campaign"],
      }),
      getCampaignById: build.query({
        query: ({ id }) => ({ url: `/campaigns/${id}`, method: "get" }),
        providesTags: ["Campaign"],
      }),
      createCampaign: build.mutation({
        query: (data) => ({
          url: `/campaigns`,
          method: "post",
          data,
        }),
        invalidatesTags: ["Campaign"],
      }),
      updateCampaign: build.mutation({
        query: ({ id, data }) => ({
          url: `/campaigns/${id}`,
          method: "put",
          data,
        }),
        invalidatesTags: ["Campaign"],
      }),
      patchCampaign: build.mutation({
        query: ({ id, data }) => ({
          url: `/campaigns/${id}`,
          method: "patch",
          data,
        }),
        invalidatesTags: ["Campaign"],
      }),
      deleteCampaign: build.mutation({
        query: ({ id }) => ({
          url: `/campaigns/${id}`,
          method: "delete",
        }),
        invalidatesTags: ["Campaign"],
      }),
      insights: build.query({
        query: ({ campaign, from, to }) => ({
          url: `/insights?campaign=${campaign}&from=${from}&to=${to}`,
          method: "get",
        }),
        providesTags: ["Campaign"],
      }),
    };
  },
});

export const {
  useGetCampaignsQuery,
  useGetCampaignByIdQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  usePatchCampaignMutation,
  useDeleteCampaignMutation,
  useInsightsQuery,
} = campaignsApi;

export default campaignsApi;
