import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "./firebase";

const AuthRoute = () => {
  const [authenticated, setAuthenticated] = React.useState<boolean | undefined>(
    undefined
  );
  const navigate = useNavigate();

  React.useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      if (user) {
        user.displayName ? navigate("/") : navigate("/actions/profile");
      } else {
        setAuthenticated(false);
      }
    });

    return () => unsub();
  }, [navigate]);

  if (authenticated === false) {
    return <Outlet />;
  }

  return null;
};

export default AuthRoute;
