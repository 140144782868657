import { customBusinessApi } from "../businessesApi";
import authApi from "../authApi";
import store from "../../app/store";
import { autocompleteApi, geocodingApi } from "../geocodingApi";
import businessCategoriesApi from "../businessCategoriesApi";

export const clearRTKApiCache = () => {
  console.log("clear RTK API Cache");
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(customBusinessApi.util.resetApiState());
  store.dispatch(geocodingApi.util.resetApiState());
  store.dispatch(autocompleteApi.util.resetApiState());
  store.dispatch(businessCategoriesApi.util.resetApiState());
};
