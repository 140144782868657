import { useState } from "react";
import * as yup from "yup";
import { IconButton, MenuItem, Stack, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";

import FormikGeoLocationPicker from "../../../components/atoms/formik/GeoLocationPicker/FormikGeoLocationPicker";
import FormikSelect from "../../../components/atoms/formik/FormikSelect";
import { useGetAllBusinessCategoriesQuery } from "../../../redux/businessesApi";
// import FormikTextField from "../../../components/atoms/formik/FormikTextField";
// import FormikSlider from '../../../components/atoms/formik/FormikSlider';

export const stepTwoSchema = {
  // adSetName: yup.string().required("Ce champ est requis."),
  address: yup.string(),
  coordinate: yup
    .object()
    .test(
      "location",
      "Veuillez saisir une adresse, une ville, un code postal ou un pays.",
      (value) => {
        return value?.longitude && value?.latitude;
      }
    ),
  // radius: yup.number().required("Champs requis"),
  placements: yup.array().of(yup.string()),
  // .min(1, "Champ obligatoire"),
  // .required("Champ obligatoire"),
  categories: yup.array().of(yup.string()),
  // .min(1, "Champ obligatoire")
  // .required("Champ obligatoire")
 // geoJSON:
};

const StepTwo = () => {
  const [showTooltip, setShowTooltip] = useState({
    page: false,
    category: false,
  });
  const businessCategories = useGetAllBusinessCategoriesQuery();

  return (
    <>
      {/* <FormikTextField name="adSetName" label="Nom" /> */}
      <FormikGeoLocationPicker
        label="Lieu d'affichage"
        title="Lieu d'affichage"
        locationName="coordinate"
        addressName="address"
        geoJSONName="geoJSON"
        fullAddress={false}
      />
      {/*<FormikSlider
        name="radius"
        label="Diamètre"
        renderValue={(val: string) => `${val} Km`}
        step={1}
        min={0}
        max={100}
      />*/}
      <Stack direction="row" spacing={1}>
        <FormikSelect name="placements" label="Pages de destination" multiple>
          <MenuItem value="site">Accueil</MenuItem>
          <MenuItem value="partners">Partenaire</MenuItem>
          <MenuItem value="catalogs">Catalogue</MenuItem>
          <MenuItem value="coupons">Coupon</MenuItem>
          <MenuItem value="posts">Actualité</MenuItem>
          <MenuItem value="nearby">À proximité</MenuItem>
        </FormikSelect>
        <Tooltip
          title="Si aucune page n'est sélectionnée la campagne s'affiche sur toutes les pages."
          arrow
          placement="left"
          open={showTooltip.page}
          onClick={() =>
            setShowTooltip({ ...showTooltip, page: !showTooltip.page })
          }
        >
          <IconButton disableRipple>
            <Info />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={1}>
        <FormikSelect
          name="categories"
          label="Catégorie d'établissement cible"
          multiple
        >
          {businessCategories.data?.map((businessesCategory) => (
            <MenuItem
              key={businessesCategory._id}
              value={businessesCategory._id}
            >
              {businessesCategory.name}
            </MenuItem>
          ))}
        </FormikSelect>
        <Tooltip
          title="Catégorie d'établissements où est affichée votre campagne. Si aucune catégorie n’est sélectionnée, votre annonce s’affiche dans tous les types d'établissements."
          arrow
          placement="left"
          open={showTooltip.category}
          onClick={() =>
            setShowTooltip({ ...showTooltip, category: !showTooltip.category })
          }
        >
          <IconButton disableRipple>
            <Info />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  );
};

export default StepTwo;
