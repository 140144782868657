import { Route, Routes } from "react-router-dom";
import { Box, useTheme } from "@mui/material";

import PageActionContainer from "./components/organisms/PageActionContainer";
import UserPageContainer from "./components/organisms/UserPageContainer";
import Logout from "./pages/Logout/Logout";
import CampaignsList from "./pages/Campaigns/CampaignsList";
import CampaignForm from "./pages/Campaigns/CampaignForm";
import Login from "./pages/Login/Login";
import Payments from "./pages/Payments/Payments";
import Statistics from "./pages/Statistics/Statistics";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import QRCodes from "./pages/QRCodes/QRCodes";
import Checkout from "./pages/Payments/Checkout";
import AuthRoute from "./lib/AuthRoute";
import PubRoute from "./lib/PubRoute";
import PaymentGuardRoute from "./lib/PaymentGuardRoute";

const AppRoutes = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        paddingBottom: 10,
        position: "relative",
      }}
    >
      <Routes>
        <Route path="" element={<PubRoute />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route path="" element={<AuthRoute />}>
          <Route path="actions" element={<PageActionContainer />}>
            <Route path="profile" element={<Profile />} />
            <Route path="payments" element={<Payments />} />
            <Route path="checkouts" element={<Checkout />} />
            <Route element={<PaymentGuardRoute />}>
              <Route path="campaigns/create" element={<CampaignForm />} />
              <Route path="campaigns/edit/:id" element={<CampaignForm />} />
            </Route>
          </Route>

          <Route path="" element={<UserPageContainer />}>
            <Route path="settings" element={<Settings />} />
            <Route element={<PaymentGuardRoute />}>
              <Route path="statistics/:id" element={<Statistics />} />
              <Route path="qrcodes" element={<QRCodes />} />
              <Route
                index
                // path="campaigns"
                element={<CampaignsList />}
              />
            </Route>
          </Route>

          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default AppRoutes;
