const getRealReferenceInFirebasestorage = (ref: string) => {
  if (ref.search(".jpg") > 0) {
    ref = ref.replace(".jpg", "_600x600.jpg");
  }

  if (ref.search(".png") > 0) {
    ref = ref.replace(".png", "_600x600.png");
  }

  return `public/${ref}`;
};

export default getRealReferenceInFirebasestorage;
