import React from "react";
import { Outlet } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Campaign,
  // Assessment,
  // ManageAccounts,
  // AccountCircle,
  // Payments,
  // Logout,
  SettingsRounded,
  QrCodeRounded
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../../assets/images/logo.png";

const MenuIcon: React.FC<{
  Icon: any;
  text: string;
}> = ({ Icon, text }) => (
  <IconButton color="inherit" sx={{ flexDirection: "column" }}>
    <Icon fontSize="medium" />
    <Typography variant="body2">{text}</Typography>
  </IconButton>
);

const MenuLink = ({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) => (
  <Link
    sx={{
      color: "#fff",
      "&, &:active": {
        textDecoration: "none",
      },
    }}
    component={RouterLink}
    to={to}
  >
    {children}
  </Link>
);

const UserPageContainer = () => {
  return (
    <>
      <AppBar color="inherit">
        <Toolbar sx={{ alignSelf: "center" }}>
          <Box
            component="img"
            sx={{ height: 34 }}
            alt="FluXence ads"
            src={Logo}
          />
          <Typography variant="h4" marginLeft={1}>
            FluXence Ads
          </Typography>
        </Toolbar>
      </AppBar>
      <Box marginTop={10}>
        <Outlet />
      </Box>
      <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar sx={{ justifyContent: "space-around", flex: 1 }}>
          <MenuLink to="/">
            <MenuIcon Icon={Campaign} text="Annonces" />
          </MenuLink>
          <MenuLink to="/qrcodes">
            <MenuIcon Icon={QrCodeRounded} text="Nos QR codes" />
          </MenuLink>
          <MenuLink to="/settings">
            <MenuIcon Icon={SettingsRounded} text="Paramètres" />
          </MenuLink>

          {/*
          <MenuLink to="/statistics">
            <MenuIcon Icon={Assessment} text="Statistiques" />
          </MenuLink>
          <MenuLink to="/payments">
            <MenuIcon Icon={Payments} text="Paiement" />
          </MenuLink>
          <MenuLink to="/actions/profile">
            <MenuIcon Icon={AccountCircle} text="Profil" />
          </MenuLink>
          <MenuLink to="/logout">
            <MenuIcon Icon={Logout} text="Logout" />
          </MenuLink>
          */}

        </Toolbar>
      </AppBar>
    </>
  );
};

export default UserPageContainer;
