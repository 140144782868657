import React from "react";

import { Button, useTheme } from "@mui/material";

const RoundTextIconButton: React.FC<
  | {
      text: string;
      icon: React.ReactNode;
      color?: "primary" | "secondary";
    } & Pick<React.ComponentProps<typeof Button>, "sx" | "onClick">
> = ({ color, icon, text = "primary", sx, ...props }) => {
  const theme = useTheme();
  const backgroundColor =
    color === "primary"
      ? theme.palette.primary.main
      : theme.palette.common.white;
  return (
    <Button
      variant="contained"
      startIcon={icon}
      sx={{
        ...sx,
        backgroundColor,
        color: theme.palette.getContrastText(backgroundColor),
        borderRadius: (theme) => Number(theme.shape.borderRadius) * 2,
        flex: 0,
        textTransform: "none",
      }}
      {...props}
    >
      {text}
    </Button>
  );
};

export default RoundTextIconButton;
