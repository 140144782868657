import React, { ComponentProps } from "react";
import { useField } from "formik";
import FileInput from "../FileInput";
import { Stack, Typography } from "@mui/material";

const FormikFileInput = ({
  name,
  ...props
}: {
  name: string;
} & Omit<
  ComponentProps<typeof FileInput>,
  "onFileSelect" | "onFileRemove"
>) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Stack spacing={1}>
      <FileInput
        {...props}
        onFileSelect={(file) => helpers.setValue(file)}
        value={field.value}
        onFileRemove={() => helpers.setValue("")}
      />

      {meta.touched && meta.error ? (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      ) : null}
    </Stack>
  );
};

export default FormikFileInput;
