import {
  deleteObject,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { createAsyncThunk } from "@reduxjs/toolkit";
import generate from "shortid";
import getRealReferenceInFirebasestorage from "../lib/getRealReferenceInFirebasestorage";
import filesApi from "./filesApi";

export const removeFile = createAsyncThunk(
  "files/removeFile",
  async (reference: AppFile, { dispatch }) => {
    const storage = getStorage();
    const fileRef = ref(
      storage,
      getRealReferenceInFirebasestorage(reference.reference)
    );
    try {
      await dispatch(
        filesApi.endpoints.deleteFile.initiate(reference._id)
      ).unwrap();
      await deleteObject(fileRef);
    } catch (err) {
      // swallow cause it should always supposed to find the reference
    }
  }
);

export const uploadFile = createAsyncThunk(
  "files/uploadFile",
  async (file: File, { dispatch }) => {
    const fileName = generate() + "_" + file.name;
    const storage = getStorage();
    const fileRef = ref(storage, "public/" + fileName);

    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise<AppFile>((res, rej) => {
      uploadTask.on(
        "state_changed",
        async (sps) => {
          try {
            switch (sps.state) {
              case "success":
                console.log("success uploading");
                break;
            }
          } catch (err) {
            rej(err);
          }
        },
        (error) => {
          console.error(error);
          rej(error);
        },
        async () => {
          const createdFile = await dispatch(
            filesApi.endpoints.createFile.initiate({
              reference: fileName,
              name: file.name,
            })
          ).unwrap();

          res(createdFile);
        }
      );
    });
    /*
    const reference = firebase.storage().ref("public/" + fileName);

    const task = reference.put(file);

    return new Promise<AppFile>((res, rej) => {
      task.on(
        "state_changed",
        async (sps) => {
          try {
            switch (sps.state) {
              case firebase.storage.TaskState.SUCCESS: // or 'running'
                console.log("success uploading");

                break;
            }
          } catch (err) {
            rej(err);
          }
        },
        (error) => {
          console.error(error);
          rej(error);
        },
        async () => {
          const createdFile = await dispatch(
            filesApi.endpoints.createFile.initiate({
              reference: fileName,
              name: file.name,
            })
          ).unwrap();

          res(createdFile);
        }
      );
    });*/
  }
);
