import React from "react";
import { Breadcrumbs, Divider, Stack, Typography } from "@mui/material";
import UserPage from "../../components/organisms/UserPage";
import DataList from "./DataList";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EndMessage = ({ visible }: { visible: boolean }) =>
  visible ? (
    <Stack direction="row" py={2} alignItems="center" spacing={4}>
      <Divider sx={{ flexGrow: 1 }} />
      <Typography variant="body1">Fin</Typography>
      <Divider sx={{ flexGrow: 1 }} />
    </Stack>
  ) : null;

const PageList = <T,>({
  pageTitle,
  ...props
}: {
  useQuery: any;
  columns?: number;
  header?: JSX.Element;
  emptyList?: JSX.Element;
  renderItem: (item: T) => JSX.Element;
  addLink?: string;
  pageTitle: string;
  addButtonVisible?: boolean;
  extraQueryParams?: any;
  paginationParams?: {
    limit: string;
    skip: string;
  };
  useQuerySkip?: boolean;
}) => {
  return (
    <UserPage
      px={1}
      flex={1}
      breadcrumbs={
        <Breadcrumbs aria-label="breadcrumb">
          <Typography variant="h6" color="text.primary">
            {pageTitle}
          </Typography>
        </Breadcrumbs>
      }
    >
      <DataList {...props} />
    </UserPage>
  );
};

export default PageList;
