import React from "react";

const AlertContext = React.createContext<{
  message: string | JSX.Element;
  title: string;
  showAlert: ({
    title,
    message,
  }: {
    message: string | JSX.Element;
    title: string;
  }) => void;
}>({
  message: "",
  title: "",
  showAlert: (_: unknown) => {},
});

export const useAlertContext = () => React.useContext(AlertContext);

export default AlertContext;
