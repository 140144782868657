import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";

const businessCategoriesApi = createApi({
  reducerPath: "businessCategoriesApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["BusinessCategory"],
  endpoints(build) {
    return {
      getBusinessCategories: build.query<
        PaginateResult<BusinessCategory>,
        {
          $limit: number;
          $skip: number;
          sortField: string;
          sortOrder: number;
          search: string;
        }
      >({
        query: ({
          $limit,
          $skip,
          sortField = "createdAt",
          sortOrder = -1,
          search = "",
        }) => {
          let url = `/business-category?$limit=${$limit}&$skip=${$skip}&$sort[${sortField}]=${sortOrder}`;
          if (search) {
            url = `${url}&search=${search}`;
          }
          return {
            url,
            method: "get",
          };
        },
        providesTags: ["BusinessCategory"],
      }),
      createBusinessCategory: build.mutation<
        BusinessCategory,
        { name: string }
      >({
        query: (data) => ({
          url: `/business-category`,
          method: "post",
          data,
        }),
        invalidatesTags: ["BusinessCategory"],
      }),
      updateBusinessCategory: build.mutation<
        BusinessCategory,
        {
          id: string;
          data: any;
        }
      >({
        query: ({ id, data }) => ({
          url: `/business-category/${id}`,
          method: "patch",
          data,
        }),
        invalidatesTags: ["BusinessCategory"],
      }),
      deleteBusinessCategory: build.mutation<
        BusinessCategory,
        {
          id: string;
        }
      >({
        query: ({ id }) => ({
          url: `/business-category/${id}`,
          method: "delete",
        }),
        invalidatesTags: ["BusinessCategory"],
      }),
    };
  },
});

export const {
  useGetBusinessCategoriesQuery,
  useCreateBusinessCategoryMutation,
  useUpdateBusinessCategoryMutation,
  useDeleteBusinessCategoryMutation,
} = businessCategoriesApi;

export default businessCategoriesApi;
