import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery } from "./apiBaseQuery";
import authApi from "./authApi";

const adUserApi = createApi({
  reducerPath: "adUserApi",
  baseQuery: apiBaseQuery,
  tagTypes: ["adUserApi"],
  endpoints(build) {
    return {
      getUserByPhoneNumber: build.query({
        query: ({ phoneNumber }) => ({
          url: `/ad-users?$limit=1&phoneNumber=${phoneNumber}`,
          method: "get",
          params: { phoneNumber },
        }),
        transformResponse: (response: any) => {
          if (response.data.length > 0) {
            return response.data[0];
          }

          return null;
        },
      }),
      createAdUser: build.mutation({
        query: (data) => ({
          url: "/ad-users",
          method: "post",
          data,
        }),
        async onQueryStarted(args, api) {
          await api.queryFulfilled;
          api.dispatch(authApi.util.invalidateTags(["AuthUser"]));
        },
      }),
      updateAdUser: build.mutation({
        query: ({ id, data }) => ({
          url: `/ad-users/${id}`,
          method: "patch",
          data,
        }),
        async onQueryStarted(args, api) {
          await api.queryFulfilled;
          api.dispatch(authApi.util.invalidateTags(["AuthUser"]));
        },
      }),
      deleteAdUser: build.mutation({
        query: ({ id }) => ({
          url: `/ad-users/${id}`,
          method: "delete",
        }),
      }),
    };
  },
});

export const {
  useGetUserByPhoneNumberQuery,
  useUpdateAdUserMutation,
  useCreateAdUserMutation,
  useDeleteAdUserMutation,
} = adUserApi;

export default adUserApi;
