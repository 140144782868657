import { Navigate, Outlet } from "react-router-dom";
import useGetAuthUserId from "./hooks/useGetAuthUserId";
import { useGetInvoiceQuery } from "../redux/paymentApi";

const PaymentGuardRoute = ({ redirectPath = '/actions/checkouts' }) => {
  const owner = useGetAuthUserId();
  const invoice = useGetInvoiceQuery(
    { user: owner?.id },
    { skip: !owner?.id, refetchOnMountOrArgChange: true },
  );

  if (invoice.data) {
    return <Navigate to={`${redirectPath}?cs=${invoice.data?.paymentIntentClientSecret}`} replace={true} />;
  }

  return <Outlet />;
};

export default PaymentGuardRoute;
