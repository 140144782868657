import { Alert, Backdrop, CircularProgress, Typography } from "@mui/material";

import PageAction from "../../components/organisms/PageAction";
import { useGetUserBillingPortalQuery } from "../../redux/paymentApi";
import useGetAuthUserId from "../../lib/hooks/useGetAuthUserId";

const Payments = () => {
  const user = useGetAuthUserId();
  const {data: billingPortal, isLoading, isSuccess} =
    useGetUserBillingPortalQuery(user.id, { skip: !user.id });

  if (billingPortal?.url) {
    window.location.href = billingPortal?.url;
    return <Typography align="center" variant="body1">Chargement en cours ...</Typography>;
  }

  return (
    <PageAction title="Paiement et factures">
      { (isSuccess && !billingPortal) &&
        <Alert severity="info">
          Votre compte ne comporte actuellement aucune facture.
        </Alert>
      }
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
    </PageAction>
  );
};

export default Payments;
