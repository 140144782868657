import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts";

const PieChartComponent = ({ title, data }: any) => {
  return (
    <Box width={"50%"}>
      <Typography sx={{ marginBottom: 2, height: "40px" }}>{title}</Typography>
      <PieChart
        series={[{ data }]}
        height={200}
        // width={400}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
            padding: 0,
            itemMarkWidth: 10,
            itemMarkHeight: 5,
            itemGap: 4,
            labelStyle: {
              fontSize: 14,
            },
          },
        }}
        margin={{ top: 0, bottom: 40, left: 0, right: 0 }}
      />
    </Box>
  );
};

export default PieChartComponent;
